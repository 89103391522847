<template>

<div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
</div>
    
</template>
    
    <script lang="ts">
    import { defineComponent, } from 'vue';
    
    export default defineComponent({
        name: 'LoadingAnimation',
        setup() {
            return {
            }
        },
    }) 
    </script>
    
    <style scoped>
    .loader {
    border-top-color: #3490dc;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
    </style>