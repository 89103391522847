<template>
    <div v-if="tripsDataAvailable" class="w-full flex">
        <div class="w-12 flex flex-col items-center">
            <button
                :class="['w-7 h-7 rounded-sm p-0.5 mt-4 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isZoomEnabled, 'bg-slate-400 text-black': isZoomEnabled }]"
                @click="handleZoom">
                <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${zoomSvg})` }"></div>
            </button>
            <button
                :class="['w-7 h-7 rounded-sm p-0.5 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isZoomEnabled, 'bg-slate-400 text-black': isZoomEnabled }]"
                @click="handleZoomReset">
                <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${resetZoom})` }"></div>
            </button>
            <button class="w-7 h-7 rounded-sm p-0.5 mb-2 bg-slate-800 text-white flex items-center justify-center"
                @click="addDraggableDiv">
                <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${addBarSvg})` }"></div>
            </button>
            <button class="w-7 h-7 rounded-sm p-0.5 mb-2 bg-slate-800 text-white flex items-center justify-center"
                @click="removeDraggableDiv">
                <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${removeBarSvg})` }"></div>
            </button>
        </div>
        <div class="container mx-auto h-1/3 flex flex-col">
            <!--padding row-->
            <div class="h-4 flex">
                <div class="w-12 ml-1 h-4"></div>
                <div class="w-full border-b-2 h-4"></div>
                <div class="w-4  h-4"></div>
            </div>
            <!-- Row 1 -->
            <div class="flex flex-grow">
                <!-- Div 1 -->
                <div class="flex-none w-12 h-full border-r-2 flex flex-col-reverse items-center justify-between">
                    <div v-for="(value, index) in yAxisValues" :key="index" class="flex items-center relative">
                        <span class="text-xs">{{ value }}</span>
                        <span class="absolute left-8 bg-gray-200"
                            :style="{ width: gridWidth + 'px', height: '1px' }"></span>
                    </div>
                </div>
                <!-- Div 2 -->
                <div ref="div2" class="div-2 w-full relative">
                    <canvas ref="zoomCanvas"
                        class="zoom-canvas absolute top-0 left-0 w-full h-full cursor-crosshair z-50 pointer-events-none"></canvas>
                    <div class="absolute -top-3 -right-2 flex z-40 bg-white border-gray-200 border-2 rounded-md">
                        <div class="legend flex items-center justify-center p-1">
                            <span
                                class="legend-indicator h-2 w-12 rounded-full bg-blue-200 mr-2 border-2 border-slate-800"></span>
                            <span class="legend-text text-sm mr-4">Rijtijd voorspeld</span>
                        </div>
                    </div>
                    <div class="flex-grow h-full relative">
                        <div v-for="(bar, index) in filteredBarsData" :key="index">
                            <div v-if="bar.height >= 0"
                                :class="['absolute bottom-0 bar-div bg-blue-200 border-l-2 border-r-2 border-slate-800', { 'border-t-0': bar.height > 100, 'border-t-2': bar.height <= 100 }]"
                                :style="{ height: (bar.height > 100 ? 100 : bar.height) + '%', left: `calc(${bar.xAxisPosition}% - ${8 / 2}px)`, width: '8px' }">
                            </div>
                        </div>

                        <div v-for="(draggable, index) in getDisplayedDraggableDivs(draggableDivs)" :key="index"
                            class="hover-container absolute bottom-0"
                            :style="{ left: `calc(${draggable.position}% - ${10}px)` }"
                            @mousedown="startDrag($event, index)">
                            <div class="draggable-div bg-gray-600" :style="{ height: '75%', width: '4px' }">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-4 border-l-2"></div>
            </div>
            <!-- Row 2 -->
            <div class="flex h-12">
                <!-- Div 3 -->
                <div class="flex-none w-12 h-full flex items-center justify-center">
                </div>
                <!-- Div 4 -->
                <div class="flex-grow h-full border-t-2 flex flex-col items-center justify-center relative">
                    <div class="flex w-full relative">
                        <div v-for="(value, index) in xAxisLabels" :key="index"
                            class="flex flex-col items-center label absolute"
                            :style="{ left: `calc(${value.position}% - ${16}px)` }">
                            <span class="absolute bottom-1 bg-gray-200 mb-2 -z-10"
                                :style="{ height: gridHeight + 'px', width: '1px' }"></span>
                            <span class="text-xs">{{ value.label }}</span>
                        </div>
                    </div>
                </div>
                <div class="w-4"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, watch, nextTick, ref, onMounted } from 'vue';
import { Bus, TimeClusterData, DayClusterData, DayTimeClusterData } from '@/types'

export default defineComponent({
    name: 'BarChartLine',
    props: {
        tripsData: {
            type: Array as PropType<Bus[]>,
            required: true,
        },
        timeClusters: {
            type: Array as PropType<DayTimeClusterData[]>,
            required: false, // Default times for vertical lines
        },
        activeDayCluster: {
            type: Number,
            required: true
        },
        dayClusters: {
            type: Array as PropType<DayClusterData[][]>,
            required: false,
        },
        reloadBarChartTrigger: {
            type: Number,
            required: true
        }
    },
    emits: ['route-single-bus', 'route-cluster-bus', 'update-time-cluster', 'barline-finished'],
    setup(props, { emit }) {
        // UI element references
        const barWidth = 6;
        const div2 = ref<HTMLElement | null>(null);
        const addBarSvg = require('@/assets/add_bar.svg');
        const removeBarSvg = require('@/assets/remove_bar.svg');
        const zoomClusterSvg = require('@/assets/zoom_cluster.svg');
        const zoomSvg = require('@/assets/zoom.svg');
        const resetZoom = require('@/assets/zoom_reset.svg')
        const gridWidth = ref(0);
        const gridHeight = ref(0);
        const draggableDivPosition = ref(0);
        const startDragX = ref(0);
        const minRange = ref<number>(0);
        const maxRange = ref<number>(0);
        const timeRange = ref<number>(0);
        const originalMinRange = ref<number>(0);
        const originalMaxRange = ref<number>(0);
        const originalTimeRange = ref<number>(0);
        const containerWidth = ref<number>(0);
        const minTotalTime = ref<number>(0)
        const maxTotalTime = ref<number>(0)
        const zoomCanvas = ref<HTMLCanvasElement | null>(null);
        const isDrawing = ref(false);
        const isZoomEnabled = ref(false);
        const zoomStartX = ref(0);
        const zoomStartY = ref(0);
        const zoomCurrentX = ref(0);
        const zoomCurrentY = ref(0);
        const xAxisLabels = ref<{ label: string, position: number }[]>([])
        const bufferXAxis = ref<number>(4) // 4% buffer for the xAxisLabels

        const handleZoom = () => {
            isZoomEnabled.value = !isZoomEnabled.value;
            const canvas = zoomCanvas.value;
            if (canvas) {
                if (isZoomEnabled.value) {
                    canvas.style.pointerEvents = 'all'; // Enable canvas interactions
                    canvas.addEventListener('mousedown', zoomOnMouseDown);
                    canvas.addEventListener('mousemove', zoomOnMouseMove);
                    canvas.addEventListener('mouseup', zoomOnMouseUp);
                } else {
                    canvas.style.pointerEvents = 'none'; // Disable canvas interactions
                    canvas.removeEventListener('mousedown', zoomOnMouseDown);
                    canvas.removeEventListener('mousemove', zoomOnMouseMove);
                    canvas.removeEventListener('mouseup', zoomOnMouseUp);
                }
            }
        };

        const handleZoomReset = () => {
            isZoomEnabled.value = false;
            computeXAxisValues();
            computeYAxisValues();
            computeBarsValues();
            zoomDraggables();
        }

        const zoomOnMouseDown = (e: MouseEvent) => {
            isDrawing.value = true;
            zoomStartX.value = e.offsetX;
            zoomStartY.value = e.offsetY;
        };

        const zoomOnMouseMove = (e: MouseEvent) => {
            if (!isDrawing.value) return;
            zoomCurrentX.value = e.offsetX;
            zoomCurrentY.value = e.offsetY;
            drawZoomRectangle();
        };

        const zoomOnMouseUp = () => {
            isDrawing.value = false;
            const canvas = zoomCanvas.value;
            if (canvas) {
                canvas.style.pointerEvents = 'none'; // Disable canvas interactions
                canvas.removeEventListener('mousedown', zoomOnMouseDown);
                canvas.removeEventListener('mousemove', zoomOnMouseMove);
                canvas.removeEventListener('mouseup', zoomOnMouseUp);
            }
            let width = zoomCanvas.value?.getBoundingClientRect().width;
            let height = zoomCanvas.value?.getBoundingClientRect().height;
            if (!height || !width) {
                throw new Error("The chart was not loaded yet!");
            }
            let minYPercent = Math.min((1 - zoomStartY.value / height), (1 - zoomCurrentY.value / height));
            let maxYPercent = Math.max((1 - zoomStartY.value / height), (1 - zoomCurrentY.value / height));
            let minXPercent = Math.min((zoomStartX.value / width), (zoomCurrentX.value / width));
            let maxXPercent = Math.max((zoomStartX.value / width), (zoomCurrentX.value / width));
            zoomYAxisValues(minYPercent, maxYPercent);
            zoomXAxisValues(minXPercent, maxXPercent);
            computeBarsValues();
            zoomDraggables();
            clearZoomRectangle();
            isZoomEnabled.value = false; // Disable zoom mode after drawing
        };

        const zoomYAxisValues = (minYPercent: number, maxYPercent: number) => {
            let numberOfTicks = 6;
            let minY = minTotalTime.value + minYPercent * (maxTotalTime.value - minTotalTime.value)
            let maxY = minTotalTime.value + maxYPercent * (maxTotalTime.value - minTotalTime.value)
            const minBound = minY;
            const maxBound = maxY;

            minTotalTime.value = minY
            maxTotalTime.value = maxY
            const tickInterval = (maxBound - minBound) / numberOfTicks;

            const ticks = [];
            for (let i = 0; i <= numberOfTicks; i++) {
                const tickValue = minBound + i * tickInterval;
                const minutes = Math.floor(tickValue / 60);
                const seconds = Math.floor(tickValue % 60);
                ticks.push(`${minutes}:${seconds.toString().padStart(2, '0')}`);
            }
            yAxisValues.value = ticks;
        };

        const zoomXAxisValues = (minXPercent: number, maxXPercent: number) => {

            const minTickValueInMs = (minXPercent <= bufferXAxis.value / 100) ? minRange.value : minRange.value + (minXPercent - bufferXAxis.value / 100) / (1 - 2 * bufferXAxis.value / 100) * timeRange.value
            const maxTickValueInMs = (maxXPercent >= 1 - bufferXAxis.value / 100) ? maxRange.value : minRange.value + (maxXPercent - bufferXAxis.value / 100) / (1 - 2 * bufferXAxis.value / 100) * timeRange.value
            minRange.value = minTickValueInMs;
            maxRange.value = maxTickValueInMs;
            timeRange.value = maxTickValueInMs - minTickValueInMs;
            const ticks = [];
            let numberTicks = 15;
            const tickInterval = timeRange.value / numberTicks - 1; // 60 minutes interval in milliseconds

            for (let i = 0; i <= numberTicks; i++) {
                let timeInMs = minTickValueInMs + i * tickInterval
                const hours = Math.floor(timeInMs / (60 * 60 * 1000)).toString().padStart(2, '0');
                const minutes = (Math.floor(timeInMs / (60 * 1000)) % 60).toString().padStart(2, '0');
                ticks.push(`${hours}:${minutes}`);
            }

            xAxisValues.value = ticks;
            let numberOfTicks = ticks.length
            xAxisLabels.value = ticks.map((item, index) => {
                return {
                    label: item,
                    position: bufferXAxis.value + (index / (numberOfTicks - 1)) * (100 - 2 * bufferXAxis.value)
                };
            })
        };

        const drawZoomRectangle = () => {
            const canvas = zoomCanvas.value;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
                    ctx.lineWidth = 1;
                    ctx.strokeRect(
                        zoomStartX.value,
                        zoomStartY.value,
                        zoomCurrentX.value - zoomStartX.value,
                        zoomCurrentY.value - zoomStartY.value
                    );
                }
            }
        };

        const clearZoomRectangle = () => {
            const canvas = zoomCanvas.value;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }
            }
        };

        const zoomDraggables = () => {
            draggableDivs.value = draggableDivs.value.map(item => {
                return {
                    position: timeToPosition(item.time),
                    time: item.time
                }
            })
        }

        // State management
        const isDragging = ref(false);

        // Responsive data variables
        const tripsDataAvailable = computed(() => props.tripsData && props.tripsData.length > 0);
        const draggableDivs = ref<{ position: number, time: number }[]>([]);
        const draggingIndex = ref<number | null>(null);
        const barClusters = ref<TimeClusterData[][]>([]);
        const xAxisValues = ref<string[]>([])
        const yAxisValues = ref<string[]>([])
        const barsData = ref<TimeClusterData[]>([])
        const filteredBarsData = ref<TimeClusterData[]>([])

        // Computation of chart dimensions
        const computeYAxisValues = () => {
            if (!averageTimesByCluster.value || averageTimesByCluster.value.length === 0) return [];
            let numberOfTicks = 6;
            const totalTimes = averageTimesByCluster.value.map(trip => trip.averageTime as number);
            minTotalTime.value = Math.min(...totalTimes) - 150 - (Math.min(...totalTimes) % 150);
            maxTotalTime.value = Math.max(...totalTimes) + 150 + (150 - Math.max(...totalTimes) % 150);
            const tickInterval = (maxTotalTime.value - minTotalTime.value) / numberOfTicks;

            const ticks = [];
            for (let i = 0; i <= numberOfTicks; i++) {
                const tickValue = minTotalTime.value + i * tickInterval;
                const minutes = Math.floor(tickValue / 60);
                const seconds = Math.floor(tickValue % 60);
                ticks.push(`${minutes}:${seconds.toString().padStart(2, '0')}`);
            }
            yAxisValues.value = ticks;
            console.log("THese are the yAxisValues, ", yAxisValues.value)
        };

        const computeXAxisValues = () => {
            if (!averageTimesByCluster.value) return [];
            const getTimeInMilliseconds = (time: string): number => {
                const [hours, minutes] = time.split(':').map(Number);
                return (hours * 60 * 60 * 1000) + (minutes * 60 * 1000);
            };
            const tripsTimes = averageTimesByCluster.value.map(trip => trip.time ? trip.time : null)
                .filter((time): time is string => time !== null);

            const minBuffer = 60 * 60 * 1000
            const startTimesInMilliseconds = tripsTimes.map(getTimeInMilliseconds);
            const minStartTimeInMilliseconds = Math.min(...startTimesInMilliseconds);
            const maxStartTimeInMilliseconds = Math.max(...startTimesInMilliseconds);
            const minTickValueInMs = minStartTimeInMilliseconds - (minStartTimeInMilliseconds % minBuffer) // Rounds down to the neares hour mark
            const maxTickValueInMs = maxStartTimeInMilliseconds + (minBuffer - (maxStartTimeInMilliseconds % minBuffer)) // Rounds up to the nearest hour mark

            minRange.value = minTickValueInMs;
            maxRange.value = maxTickValueInMs;
            timeRange.value = maxTickValueInMs - minTickValueInMs;
            originalMinRange.value = minTickValueInMs;
            originalMaxRange.value = maxTickValueInMs;
            originalTimeRange.value = maxTickValueInMs - minTickValueInMs;


            const ticks = [];
            const tickInterval = minBuffer; // 60 minutes interval in milliseconds

            for (let timeInMs = minTickValueInMs; timeInMs <= maxTickValueInMs; timeInMs += tickInterval) {
                const hours = Math.floor(timeInMs / (60 * 60 * 1000)).toString().padStart(2, '0');
                const minutes = (Math.floor(timeInMs / (60 * 1000)) % 60).toString().padStart(2, '0');
                ticks.push(`${hours}:${minutes}`);
            }

            xAxisValues.value = ticks;
            let numberOfTicks = ticks.length
            xAxisLabels.value = ticks.map((item, index) => {
                return {
                    label: item,
                    position: bufferXAxis.value + (index / (numberOfTicks - 1)) * (100 - 2 * bufferXAxis.value)
                };
            })
            console.log("This is the relevant input: ", )
            console.log("THese are the Xaxis values: ", xAxisValues.value)
        };

        const calculateGridDimensions = () => {
            if (div2.value) {
                containerWidth.value = div2.value.clientWidth;
                gridWidth.value = div2.value.clientWidth + 8;
                gridHeight.value = div2.value.clientHeight + 8;
            }
        }


        // Position and time related helper functions
        const timeToPosition = (time: number): number => {
            const relativeTime = time - minRange.value;
            return (relativeTime / timeRange.value) * (100 - 2 * bufferXAxis.value) + bufferXAxis.value; //maybe *100?
        };

        const positionToTime = (position: number): number => {
            const relativePosition = (position - bufferXAxis.value) / (100 - 2 * bufferXAxis.value)
            return minRange.value + relativePosition * timeRange.value;
        };

        const getTimeInMilliseconds = (time: string): number => {
                const [hours, minutes] = time.split(':').map(Number);
                return (hours * 60 * 60 * 1000) + (minutes * 60 * 1000);
            };
        // Function for determining the data for the bars
        const computeBarsValues = () => {

            const averageTimes = averageTimesByCluster.value;
            if (!averageTimes.length) return [];
            const range = maxTotalTime.value - minTotalTime.value;

            let bars = averageTimes.map(item => {
                const timeParts = item.time.split(':');
                const startTimeMs = parseInt(timeParts[0], 10) * 60 * 60 * 1000 + parseInt(timeParts[1], 10) * 60 * 1000;
                const xAxisPosition = timeToPosition(startTimeMs);
                const height = ((item.averageTime - minTotalTime.value) / range) * 100;

                return {
                    height,
                    xAxisPosition,
                    starttime: item.time,
                    journeynumber: item.journeynumber,
                    seconds: item.averageTime
                };
            });
            barsData.value = bars;
            filteredBarsData.value = bars.filter(item => getTimeInMilliseconds(item.starttime as string) >= minRange.value && getTimeInMilliseconds(item.starttime as string) <= maxRange.value)
        };

        // Averaging and aggregating data, tricky part of the code
        const averageTimesByCluster = computed(() => {
            if (!props.dayClusters) {
                throw new Error("Undefined props.dayClusters");
            }
            if (!props.dayClusters[props.activeDayCluster]) return [];

            const activeDays = props.dayClusters[props.activeDayCluster].map(dayData => dayData.day);
            const filteredTrips = props.tripsData.filter(trip => activeDays.includes(trip.day));
            const tripsGroupedByTime: { [key: string]: {total_time: number, journeynumber: string}[] } = {};

            filteredTrips.forEach(trip => {
                if (trip.start_time && trip.total_time && trip.id) {
                    const time = trip.start_time.substring(0, 5);
                    
                    // Extract journeynumber from trip.id
                    const idParts = trip.id.split('_');
                    const journeynumber = idParts[3]; // Assuming the journeynumber is always at this position

                    if (!tripsGroupedByTime[time]) {
                        tripsGroupedByTime[time] = [];
                    }
                    
                    // Push an object containing total_time and journeynumber
                    tripsGroupedByTime[time].push({total_time: trip.total_time, journeynumber });
                }
            });

            const averageTimes = Object.keys(tripsGroupedByTime).map(time => {
                const totalTimes = tripsGroupedByTime[time];
                
                // Calculate the average time and add the journeynumber
                const averageTime = totalTimes.reduce((sum, entry) => sum + entry.total_time, 0) / totalTimes.length;
                
                // Assume that all trips at the same time have the same journeynumber, so just take the first one
                const journeynumber = totalTimes[0].journeynumber;

                return { time, averageTime, journeynumber };
            });
            return averageTimes;
        });


        // Function for keeping track of the clusters
        const updateClusters = () => {
            if (!barsData.value.length || !div2.value) return;

            const timeToMs = (time: string): number => {
                const [hours, minutes] = time.split(':').map(Number);
                return (hours * 3600 + minutes * 60) * 1000;
            };

            const clusters: TimeClusterData[][] = [];
            const sortedBars = [...barsData.value]
                .filter(bar => bar.xAxisPosition !== undefined)
                .sort((a, b) => (a.xAxisPosition ?? 0) - (b.xAxisPosition ?? 0));
            const sortedDivs = draggableDivs.value.map(div => div.time).sort((a, b) => a - b);
            let currentCluster: TimeClusterData[] = [];

            sortedBars.forEach(bar => {
                if (bar.starttime === undefined) return;
                const barTimeMs = timeToMs(bar.starttime);
                if (sortedDivs.length === 0 || barTimeMs < sortedDivs[0]) {
                    currentCluster.push(bar);
                } else {
                    while (sortedDivs.length && barTimeMs >= sortedDivs[0]) {
                        if (currentCluster.length) {
                            clusters.push(currentCluster);
                        }
                        currentCluster = [];
                        sortedDivs.shift();
                    }
                    currentCluster.push(bar);
                }
            });

            if (currentCluster.length) {
                clusters.push(currentCluster);
            }

            barClusters.value = clusters;
            console.log("These are the barClusters.value", barClusters.value)
            console.log("this is the barsData", barsData.value)
            console.log("the clusters were updated")
            handleUpdateSettings();
        };


        // Functions and watchers for updating the clusters, charts and emitting the settings
        const handleUpdateSettings = () => {
            emit('update-time-cluster', barClusters.value)
        }

        watch(() => props.activeDayCluster, async () => {
            await nextTick();
            calculateGridDimensions();
            computeXAxisValues();
            computeYAxisValues();
            computeBarsValues();
            initializeDraggablePositions();
        }, { deep: true, immediate: true });

        watch(() => props.reloadBarChartTrigger, async () => {
            await nextTick();
            calculateGridDimensions();
            computeXAxisValues();
            computeYAxisValues();
            computeBarsValues();
            initializeDraggablePositions();
        }, { deep: true, immediate: true });

        const resizeHandler = () => {
            window.addEventListener("resize", handleOnMounted);
        };

        const handleOnMounted = () => {
            console.log("THe chart is mounted")
            resizeHandler();
            calculateGridDimensions();
            computeXAxisValues();
            computeYAxisValues();
            computeBarsValues();
            initializeDraggablePositions();
            nextTick(() => {
                if (zoomCanvas.value && div2.value) {
                    zoomCanvas.value.width = div2.value.clientWidth;
                    zoomCanvas.value.height = div2.value.clientHeight;
                }
                emit('barline-finished')
                console.log("The bar line is actually finished!")
            });
        }
        onMounted(handleOnMounted);





        // Functions for creating and moving the cluster splitters
        const initializeDraggablePositions = () => {
            if (!div2.value || !props.timeClusters) return;

            const timeClusterName = getActiveTimeClusterName();
            if (props.timeClusters?.filter(item => item.day_cluster === timeClusterName && item.time_cluster.length !== 0).length !== 0) {
                const midPoints = findMidpoints(props.timeClusters)
                draggableDivs.value = midPoints.map(time => {
                    const [hours, minutes] = time.split(':').map(Number);
                    const timeInMs = hours * 3600 * 1000 + minutes * 60 * 1000;
                    const position = timeToPosition(timeInMs);
                    return {
                        position,
                        time: timeInMs
                    };
                });

            } else {
                draggableDivs.value = [{ position: 50, time: positionToTime(50) }]
            }
            updateClusters();
        };

        const addDraggableDiv = () => {
            let position = 50;
            let threshold = 2;
            const isTooClose = (newPosition: number) => {
                return draggableDivs.value.some(div => Math.abs(div.position - newPosition) < threshold);
            };
            while (isTooClose(position)) {
                position += threshold;
            }
            draggableDivs.value.push({ position: position, time: positionToTime(position) });
            updateClusters();
        }

        const removeDraggableDiv = () => {
            draggableDivs.value.pop();
            updateClusters();
        }

        const getDisplayedDraggableDivs = (draggable: {position: number, time: number}[]) => {
            return draggable.filter(item => item.position <= 96 && item.position >= 4)
        }

        const getActiveTimeClusterName = (): string => {
            if (!props.dayClusters) {
                throw new Error("There is no active day cluster, this should not be possible");
            }
            const activeDaysCluster = props.dayClusters[props.activeDayCluster];
            const activeDays = activeDaysCluster.map(dayObj => dayObj.day).join('_');
            return activeDays;
        };

        function findMidpoints(dayTimeArrays: DayTimeClusterData[]): string[] {
            const midpoints: string[] = [];
            const timeClusterName = getActiveTimeClusterName();
            const timeArrays = dayTimeArrays.filter(item => item.day_cluster === timeClusterName && item.time_cluster).flatMap(item => item.time_cluster);

            const timeToMinutes = (time: string): number => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            const minutesToTime = (minutes: number): string => {
                const hours = Math.floor(minutes / 60);
                const mins = minutes % 60;
                return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
            };

            if (!timeArrays) {
                throw new Error("There is not matching time cluster found")
            }

            for (let i = 0; i < timeArrays.length - 1; i++) {
                const endTime = timeArrays[i][timeArrays[i].length - 1].starttime;
                const nextStartTime = timeArrays[i + 1][0].starttime;
                const endTimeMinutes = timeToMinutes(endTime as string);
                const nextStartTimeMinutes = timeToMinutes(nextStartTime as string);
                const midpointMinutes = Math.floor((endTimeMinutes + nextStartTimeMinutes) / 2);
                midpoints.push(minutesToTime(midpointMinutes));
            }
            return midpoints;
        }

        const startDrag = (event: MouseEvent, index: number) => {
            isDragging.value = true;
            startDragX.value = event.clientX;
            const count = draggableDivs.value.filter(item => item.position < 4).length;
            draggingIndex.value = index + count;
            document.addEventListener('mousemove', onDrag);
            document.addEventListener('mouseup', stopDrag);
        };

        const onDrag = (event: MouseEvent) => {
            if (isDragging.value && draggableDivs.value && div2.value && draggingIndex.value !== null) {
                const dx = event.clientX - startDragX.value;
                let paddingCompensation = 32 // Callibration for the padding
                const dxPercent = (dx / (containerWidth.value - paddingCompensation)) * 100;
                let newPosition = draggableDivs.value[draggingIndex.value].position + dxPercent;

                if (newPosition < 0) {
                    newPosition = 0;
                } else if (newPosition > 100) {
                    newPosition = 100;
                }

                draggableDivs.value[draggingIndex.value].position = newPosition;
                startDragX.value = event.clientX;
            }
        };

        const stopDrag = () => {
            isDragging.value = false;
            document.removeEventListener('mousemove', onDrag);
            document.removeEventListener('mouseup', stopDrag);
            if (draggingIndex.value !== null) {
                checkForOverlap(draggingIndex.value);
                draggableDivs.value[draggingIndex.value].time = positionToTime(draggableDivs.value[draggingIndex.value].position)
            }
            draggingIndex.value = null;
            updateClusters();
        };

        const checkForOverlap = (index: number) => {
            if (!div2.value) return;

            const draggableDiv = div2.value.querySelectorAll('.draggable-div')[index];
            if (!draggableDiv) return;

            const draggableWidth = draggableDiv.clientWidth;
            const bars = Array.from(div2.value.querySelectorAll('.bar-div'));

            bars.forEach(bar => {
                let draggableRect = draggableDiv.getBoundingClientRect();
                const barRect = bar.getBoundingClientRect();
                if (
                    draggableRect.left < barRect.right &&
                    draggableRect.right > barRect.left
                ) {
                    // Overlapping
                    const overlapLeft = draggableRect.left - barRect.left;
                    const overlapRight = barRect.right - draggableRect.right;
                    if (Math.abs(overlapLeft) < Math.abs(overlapRight)) {
                        draggableDivs.value[index].position -= (draggableWidth + overlapLeft + 2) / containerWidth.value * 100;
                    } else {
                        draggableDivs.value[index].position += (draggableWidth + overlapRight + 2) / containerWidth.value * 100;
                    }
                    draggableRect = draggableDiv.getBoundingClientRect();
                }
            });
        };

        const getClusterWidth = (barCluster: TimeClusterData[]): string => {
            if (!barCluster.length) {
                return '0%';
            }

            const positions = barCluster.map(bar => bar.xAxisPosition).filter((pos): pos is number => pos !== undefined);

            if (positions.length === 0) {
                return '0%';
            }

            const minPosition = Math.min(...positions);
            const maxPosition = Math.max(...positions);

            if (minPosition === maxPosition) {
                return `5%`;
            } else {
                return `${maxPosition - minPosition}%`;
            }

        };

        return {
            yAxisValues,
            xAxisValues,
            barsData,
            tripsDataAvailable,
            barWidth,
            gridWidth,
            gridHeight,
            div2,
            draggableDivs,
            draggableDivPosition,
            startDrag,
            barClusters,
            getClusterWidth,
            addDraggableDiv,
            removeDraggableDiv,
            addBarSvg,
            removeBarSvg,
            zoomClusterSvg,
            zoomSvg,
            resetZoom,
            handleZoom,
            zoomCanvas,
            isZoomEnabled,
            xAxisLabels,
            handleZoomReset,
            filteredBarsData,
            getDisplayedDraggableDivs
        };
    },
});

</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
}


/* Container for hover effect */
.hover-container {
    @apply absolute bottom-0 flex justify-center;
    height: 100%;
    width: 20px;
    /* Expanded width for hover effect */
    cursor: ew-resize;
}

/* Skinny div */
.draggable-div {
    @apply bg-gray-600 absolute bottom-0;
    width: 4px;
    height: 75%;
    transition: all 0.3s ease;
    /* Smooth transition */
    z-index: 10;
}

/* Hover effect */
.hover-container::before {
    @apply absolute w-full h-full bg-black bg-opacity-0 transition-opacity duration-300 ease-in-out;
    content: '';
}

.hover-container:hover::before {
    @apply bg-opacity-10;
}
</style>