<template>
    <transition name="fade">
        <div v-if="message" class="fixed top-0 left-1/2 transform -translate-x-1/2 text-black p-4 rounded shadow-lg"
            :class="messageClass">
            <p>{{ message }}</p>
        </div>
    </transition>
    <transition name="fade">
        <div v-if="resetMessage"
            class="fixed top-0 left-1/2 transform -translate-x-1/2 text-black p-4 rounded shadow-lg z-50"
            :class="messageClass">
            <p>{{ resetMessage }}</p>
        </div>
    </transition>
    <transition name="fade">
        <div v-if="saveMessage"
            class="fixed top-0 left-1/2 transform -translate-x-1/2 text-black p-4 rounded shadow-lg z-50"
            :class="messageClass">
            <p>{{ saveMessage }}</p>
        </div>
    </transition>
    <div v-if="isLoading">
        <LoadingAnimation />
    </div>
    <div v-else class="flex flex-col h-screen">
        <div class="flex w-full h-10 navbar bg-slate-900 px-8 items-center">
            <div class="menu-wrapper flex flex-shrink-0">
                <button
                    class="flex w-36 mx-2 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="returnToHome()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    <p>Lijn selectie</p>
                </button>

            </div>
            <div class="flex-grow"></div>
            <div class="flex items-center text-white">
                <button
                    class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="saveToServer()">
                    <p>Save</p>
                </button>
                <div class="relative">
                    <button
                        class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                        @click="handleLoadButton">
                        <p>Load</p>
                        <svg class="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06-.02L10 10.44l3.71-3.25a.75.75 0 111.04 1.08l-4 3.5a.75.75 0 01-1.04 0l-4-3.5a.75.75 0 01-.02-1.06z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                    <div v-if="loadDropdown"
                        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                        <div class="py-1 z-50">
                            <button v-for="session in sessionList" :key="session.id as string"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                @click="selectSession(session.id as string)">
                                {{ session.id }} - {{ session.datetime }}
                            </button>
                        </div>
                    </div>
                </div>
                <button
                    class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="fullReset()">
                    <p>Reset</p>
                </button>
                <span class="mx-4">{{ userNameLocalOrProps }}</span>
            </div>
        </div>
        <div class="flex h-full overflow-hidden">
            <div class="w-1/4 bg-white p-2 flex flex-col shadow-lg">
                <!-- info and percentiel selection -->
                <div class="flex flex-col items-end">
                    <div class="w-full flex items-center justify-end py-6">
                        <!-- Bus information aligned to the right -->
                        <div class="text-left">
                            <p>
                                <strong>Buslijn {{ linePublicNumberLocalOrProps }}</strong> <br>
                                {{ lineNameLocalOrProps }} <br>
                                <em>Routepatroon {{ journeyPatternCodeLocalOrProps }}</em>
                            </p>
                        </div>

                        <!-- Dropdown aligned to the right and takes half the width -->
                        <div class="w-1/2 ml-4 relative">
                            <div class="text-right mb-2">
                                <label class="font-semibold">Kies Percentiel</label>
                            </div>
                            <div class="dropdown">
                                <button @click="togglePercentielen"
                                    class="dropdown-button w-full flex bg-gray-200 hover:bg-gray-300 text-black text-sm py-2 px-4 rounded">
                                    <svg v-if="!isPercentielenOpen" xmlns="http://www.w3.org/2000/svg"
                                        class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M5 15l7-7 7 7" />
                                    </svg>
                                    <span>{{ selectedPercentiel }} %</span>
                                </button>
                                <!-- The dropdown menu is absolutely positioned and overlayed -->
                                <ul v-if="isPercentielenOpen"
                                    class="dropdown-menu absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-50">
                                    <li v-for="percentiel in percentielen" :key="percentiel"
                                        @click="selectPercentiel(percentiel)"
                                        class="dropdown-item px-4 py-2 cursor-pointer hover:bg-gray-100">
                                        {{ percentiel }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <button @click="toggleCollapseChooseStops"
                    class="w-full bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded my-1 flex items-center">
                    <svg v-if="collapseChooseStops" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                    <span>{{ collapseChooseStops ? 'Kies Haltes' : 'Kies Haltes' }}</span>
                </button>
                <transition name="slide">
                    <div v-if="!collapseChooseStops" class="flex-col">
                        <LineClusteringOverview :routeOrder="routeOrder" :settings="linePlanningSettings"
                            @update-settings="handleUpdateStops" />
                    </div>
                </transition>
                <button @click="toggleCollapseChooseDays"
                    class="w-full bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded my-1 flex items-center">
                    <svg v-if="collapseChooseDays" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                    <span>{{ collapseChooseDays ? 'Kies Dagen' : 'Kies Dagen' }}</span>
                </button>
                <transition name="slide">
                    <div v-if="!collapseChooseDays" class="flex-grow overflow-auto">
                        <BarChartDays :daysData="daysData" :dayClusters="linePlanningSettings.day_clusters"
                            :barChartDayTrigger="barChartDayTrigger" @update-day-cluster="handleUpdateDays"
                            @barday-finished="handleDaychartFinished" />
                    </div>
                </transition>
            </div>
            <div class="w-3/4 h-full flex flex-col p-4">
                <div class="shadow-lg flex flex-col h-full rounded-sm">
                    <div class="flex bg-gray-200 rounded-t-sm">
                        <div class="w-16"></div>
                        <button v-for="(clusterText, index) in dayClusterTexts" :key="index"
                            class="bg-gray-200 py-2 px-4 hover:bg-gray-100" @click="handleDayClusterClick(index)"
                            :class="{ 'active-day-cluster': index === activeDayCluster }">
                            {{ clusterText }}
                        </button>
                    </div>
                    <div v-if="tripsDataAvailable" class="barchartline-container flex">
                        <BarChartLine :tripsData="tripsData" :activeDayCluster="activeDayCluster"
                            :dayClusters="linePlanningSettings.day_clusters"
                            :timeClusters="linePlanningSettings.time_clusters"
                            :reloadBarChartTrigger="reloadBarChartTrigger"
                            @update-time-cluster="handleUpdateTimeCluster" @barline-finished="handleBarchartFinished" />
                    </div>

                    <div class="flex w-full items-center bg-gray-200 ">
                        <div class="w-16"></div>
                        <div v-if="showPredictionTable" class="flex overflow-x-auto">
                            <button v-for="(timeCluster, clusterIndex) in getActiveTimeClusters()" :key="clusterIndex"
                                class="bg-gray-200 py-2 px-4 flex justify-center items-center text-xs hover:bg-gray-100"
                                @click="handleTimeClusterClick(clusterIndex)"
                                :class="{ 'active-time-cluster': clusterIndex === activeTimeCluster }">
                                <span class="truncate">Cluster {{ getTimeClusterInfo(clusterIndex) }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="flex-grow">
                        <PredictionTable v-if="showPredictionTable" :route="routeOrder" :percentiel="selectedPercentiel"
                            :predictions="combinedPredictions" :schedule="lineSchedule" :linePlanningSettings="linePlanningSettings"
                            :activeTimeCluster="activeTimeCluster" :activeDayCluster="activeDayCluster"
                            :key="predictionTrigger" :timeClusterTrigger="timeClusterTrigger"
                            @update-chosen-times="updateChosenTimes" :chosenMemory="chosenMemory" />
                        <div v-else class="flex h-full items-center justify-center">
                            <LoadingAnimationComp />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, nextTick } from 'vue';
import {
    Bus,
    Lijn,
    LinePlanningSettings,
    Route,
    RouteMessage,
    Day,
    DayData,
    PREDICTION,
    Block,
    TimeClusterData,
    DayClusterData,
    AveragedPrediction,
    Chosen,
    ChosenMemory,
    SessionData,
    SessionShorthand,
    Schedule,
    LineSchedule
} from '@/types'
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import BarChartLine from '@/components/BarChartLine.vue';
import LineClusteringOverview from '@/components/LineClusteringOverview.vue'
import BarChartDays from '@/components/BarChartDays.vue'
import PredictionTable from '@/components/PredictionTable.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import LoadingAnimationComp from '@/components/LoadingAnimationComp.vue'


export default defineComponent({
    name: 'SingleLijnSchedule',
    components: {
        BarChartLine,
        LineClusteringOverview,
        BarChartDays,
        PredictionTable,
        LoadingAnimation,
        LoadingAnimationComp
    },
    props: {
        linePublicNumber: {
            type: String,
            required: true
        },
        lineId: {
            type: String,
            required: true
        },
        journeyPatternCode: {
            type: String,
            required: true
        },
    },

    setup(props) {
        // Setting local variables stored client side
        const userNameLocalOrProps = ref<string | null>(localStorage.getItem('userName'));
        const userIdLocalOrProps = ref<string | null>(localStorage.getItem('userId'));
        const lineNameLocalOrProps = ref<string | null>(localStorage.getItem('lineName'));
        const journeyPatternCodeLocalOrProps = ref<string | null>(localStorage.getItem('journeyPatternCode'));
        const linePublicNumberLocalOrProps = ref<string | null>(localStorage.getItem('linePublicNumber'));


        // Setting responsive data variables
        const tripsData = ref<Bus[]>([]);
        const message = ref<string | null>(null);
        const routeOrder = ref<Route[]>([]);
        const daysData = ref<Day[]>([]);
        const activeDayCluster = ref<number>(0);
        const activeTimeCluster = ref<number>(0);
        const allPredictions = ref<PREDICTION[]>([]);
        const predictedStops = ref<string[]>([]);
        const combinedPredictions = ref<PREDICTION[]>([]);
        const chosenMemory = ref<ChosenMemory[]>([]);
        const sessionList = ref<SessionShorthand[]>([]);

        const error = ref<string>("");
        let messageType = ref<string>("");
        let resetMessage = ref<string>("");
        let saveMessage = ref<string>("");

        const dayMapper: { [key: string]: string } = {
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            sunday: 'Zondag'
        };


        // Setting state management variables
        const isLoading = ref<boolean>(true);
        const routeLoading = ref<boolean>(true);
        const singlePredictionLoading = ref<boolean>(true);
        const predictionLoading = ref<boolean>(true);
        const collapseChooseStops = ref<boolean>(true);
        const collapseChooseDays = ref<boolean>(false);
        const showPredictionTable = ref<boolean>(false);
        const tripsDataAvailable = ref<boolean>(false);
        const loadDropdown = ref<boolean>(false);
        const loadedTimeClustersFromDb = ref<boolean>(false);
        const reloadBarChartTrigger = ref<number>(0);
        const predictionTrigger = ref<number>(0);
        const barChartDayTrigger = ref<number>(0);
        const percentielen = ref<number[]>([50, 80]);
        const selectedPercentiel = ref<number>(50);
        const isPercentielenOpen = ref<boolean>(false);
        const schedule = ref<Schedule[]>([]);
        const scheduleLoading = ref<boolean>(true);
        const lineSchedule = ref<LineSchedule[]>([])

        // Setting the global route variable to access parameters passed from the HomePage
        const route = useRoute();


        // Initializing the LinePlanningSettings this variable stores all the information necessary for building the front end
        const linePlanningSettings = ref<LinePlanningSettings>({
            line_id: props.lineId as string, time_clusters: [], day_clusters: [], stop_combinations: []
        });

        const fetchPredictedStops = async () => {
            try {
                const response = await axios.get<{ status: string, stops: string[] }>(`${process.env.VUE_APP_BACKEND_URL}/predictedstops/${props.lineId}`)
                predictedStops.value = response.data.stops.map(item => {
                    let substrings = item.split('_');
                    return substrings[substrings.length - 2]
                });
            } catch (err) {
                error.value = 'Failed to load a single prediction for this line'
                console.error(err)
            } finally {
                singlePredictionLoading.value = false
            }
        }

        const fetchRoute = async () => {
            try {
                const response = await axios.get<RouteMessage>(`${process.env.VUE_APP_BACKEND_URL}/routeoverzicht/${props.lineId}`);
                if (response.data.ROUTE) {
                    routeOrder.value = response.data.ROUTE;
                } else {
                    routeOrder.value = [];
                }
            } catch (err) {
                error.value = 'Failed to load the order data';
                console.error(err);
            } finally {
                routeLoading.value = false;
            }
        };

        const fetchPredictionLijn = async () => {
            try {
                const response = await axios.get<PREDICTION[]>(`${process.env.VUE_APP_BACKEND_URL}/allpredictions/${props.lineId}`);
                allPredictions.value = response.data;
                console.log("These are all predictions: ", allPredictions.value)
                tripsData.value = determineTotalTimesFromPrediction(allPredictions.value);
                daysData.value = determineDaysData(tripsData.value);
            } catch (err) {
                error.value = 'Failed to load blocks data';
                console.error(err);
            } finally {
                predictionLoading.value = false;
            }
        };

        const fetchAllSessions = async () => {
            try {
                const response = await axios.post<SessionShorthand[]>(`${process.env.VUE_APP_BACKEND_URL}/get_all_sessions`, {
                    user_id: userIdLocalOrProps.value,
                    line_id: props.lineId,
                });
                sessionList.value = response.data.map(item => {
                    if (!item.datetime) {
                        throw new Error("Missing date time")
                    }
                    const formattedDate = formatDate(item.datetime)
                    return {
                        ...item,
                        datetime: formattedDate
                    }
                })
            } catch (err) {
                console.error('Failed to load sessions:', err);
            }
        };

        const fetchSingleSession = async (session_id: string) => {
            try {
                const response = await axios.get<SessionData>(`${process.env.VUE_APP_BACKEND_URL}/get_session/${session_id}`);
                loadedTimeClustersFromDb.value = true;
                if (!response.data.line_planning_settings || !response.data.chosen_memory) {
                    throw new Error("No session details were provided in the save file");
                }
                linePlanningSettings.value = response.data.line_planning_settings
                chosenMemory.value = response.data.chosen_memory
            } catch (err) {
                console.error('Failed to load single session:', err);
            }
        };


        const fetchSchedule = async () => {
            try {
                const response = await axios.get<Schedule[]>(`${process.env.VUE_APP_BACKEND_URL}/schedule/${props.lineId}`);
                schedule.value = response.data;
                lineSchedule.value = transformSchedule(schedule.value)

                console.log("This is the full schedule: ", schedule.value)
            } catch (err) {
                error.value = 'Failed to load blocks data';
                console.error(err);
            } finally {
                scheduleLoading.value = false;
            }
        }


        // Data sending to the back end
        const uploadToServer = async (sessionData: SessionData) => {
            try {
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/save_session`, sessionData);
                if (!response) {
                    throw new Error("some serverside error for uploading the session")
                }
                const baseMessage = 'De sessie settings zijn opgeslagen!';
                saveMessage.value = baseMessage;
                messageType.value = 'good';
                setTimeout(() => {
                    saveMessage.value = '';
                }, 3000);
            } catch (err) {
                console.error('Failed to save session data:', err);
                messageType.value = 'bad';
                const baseMessage = 'De sessie is niet opgeslagen, error: ';
                saveMessage.value = baseMessage.concat(err as string);
            }
        };


        // Handling data loading and state management based on the loaded data
        const loadData = async () => {
            await Promise.all([fetchRoute(), fetchAllSessions(), fetchPredictedStops(), fetchPredictionLijn(), fetchSchedule()]);
            initializeStandardStops();
            isLoading.value = false;
        };

        const handleOnMounted = () => {
            loadData();
        };
        onMounted(handleOnMounted);


        function transformSchedule(schedule: Schedule[]): LineSchedule[] {
            const groupedSchedules: { [key: string]: LineSchedule } = {};

            schedule.forEach(entry => {
                // Split the ID into parts and remove the segment with the stop IDs (e.g., 54470130_54470150)
                const idParts = entry.id.split('_');
                const highOrderId = `${idParts[0]}_${idParts[1]}_${idParts[2]}_${idParts[3]}_${idParts[6]}`;

                // If the high order ID doesn't exist in groupedSchedules, create a new entry
                if (!groupedSchedules[highOrderId]) {
                    groupedSchedules[highOrderId] = { id: highOrderId, schedule: [] };
                }

                // Add the current entry to the corresponding schedule list
                groupedSchedules[highOrderId].schedule.push(entry);
            });

            // Convert the groupedSchedules object to an array
            return Object.values(groupedSchedules);
        }

        function extractDayAndTime(id: string) {
            const parts = id.split('_');
            const day = parts[4];  // Assuming the 4th part is the day
            const startTime = parts[6];  // Assuming the 6th part is the start time
            return { day, startTime };
        }

        const determineTotalTimesFromPrediction = (data: PREDICTION[]): Lijn[] => {
            const percentielSelector = (selectedPercentiel.value / 100).toFixed(1);
            const lineData = data
                .map(item => {
                    const { id, single_prediction } = item;
                    if (!single_prediction) {
                        throw new Error("No prediction on this id")
                    }
                    const filteredPredictions = single_prediction.filter(pred => {
                        if (!pred.id) {
                            throw new Error("there is no prediction id available")
                        }
                        return pred.id.endsWith(`_${percentielSelector}`)
                    });

                    // Calculate the total time based on the filtered predictions
                    const total_time = filteredPredictions.reduce((sum, pred) => sum + (pred.time || 0), 0);


                    // Extract the day and start time
                    const { day, startTime } = extractDayAndTime(id);
                    return {
                        id,
                        total_time,
                        day,
                        start_time: startTime
                    };
                })
                .filter(item => item.total_time !== 0);
            tripsDataAvailable.value = true;
            return lineData;
            }


        // Functions for handling the saving and loading of different sessions from the backend
        const handleLoadButton = () => {
            loadDropdown.value = !loadDropdown.value;
        };

        const selectSession = async (session_id: string) => {
            isLoading.value = true;
            try {
                await fetchSingleSession(session_id);
            } catch (err) {
                console.error(err);
            } finally {
                isLoading.value = false;
                loadDropdown.value = false;
            }
        };

        const saveToServer = async () => {
            const sessionData: SessionData = {
                user_id: userIdLocalOrProps.value as string,
                line_id: props.lineId,
                line_planning_settings: linePlanningSettings.value,
                chosen_memory: chosenMemory.value
            }
            await Promise.all([uploadToServer(sessionData)]);
            fetchAllSessions();
        }


        // Functions for handling storing the current state of the chosen times
        const updateChosenTimes = (chosenTimesSum: Chosen[]) => {
            if (!linePlanningSettings.value.day_clusters) {
                throw new Error("there is no active day_cluster");
            }
            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("No active time cluster");
            }
            const dayId = linePlanningSettings.value.day_clusters[activeDayCluster.value].map(day => day.day).join('_');
            const timeCluster = getActiveTimeClusters();
            if (!timeCluster) {
                throw new Error("There is no time cluster known");
            }
            const timeId = timeCluster[activeTimeCluster.value].map(time => time.starttime).join('_');
            const clusterId = dayId + '_' + timeId;
            const index = chosenMemory.value.findIndex(obj => obj.clusterId === clusterId);
            if (index !== -1) {
                chosenMemory.value[index] = { clusterId, dayId, timeId, chosenTimesSum };
            } else {
                chosenMemory.value.push({ clusterId, dayId, timeId, chosenTimesSum });
            }
            predictionTrigger.value += 1;
        }

        const togglePercentielen = () => {
            isPercentielenOpen.value = !isPercentielenOpen.value;
        }

        const selectPercentiel = (option: number) => {
            selectedPercentiel.value = option;
            isPercentielenOpen.value = false;
            showPredictionTable.value = false;
            tripsDataAvailable.value = false;
            tripsData.value = determineTotalTimesFromPrediction(allPredictions.value)
            daysData.value = determineDaysData(tripsData.value)
            chosenMemory.value = [];
            predictionTrigger.value += 1;
            reloadBarChartTrigger.value += 1;
            barChartDayTrigger.value += 1;

        };

        // Helper functions for transforming data types 
        function getTimeFromId(id: string): string {
            const time = id.split('_')[6];
            const timeParts = time.split(':');
            return `${timeParts[0]}:${timeParts[1]}`;
        }

        function getDayFromId(id: string): string {
            return id.split('_')[4];
        }

        const formatDate = (datetime: string): string => {
            const listOfDate = datetime.split('-');
            let year = listOfDate[0];
            let month = listOfDate[1];
            let day = listOfDate[2].split('T')[0];
            let time = listOfDate[2].split('T')[1].split(':');
            let hours = time[0];
            let minutes = time[1];
            let seconds = time[2].split('.')[0]
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        function convertToTimeStrings(data?: TimeClusterData[][]): string[][] {
            if (!data) {
                return [];
            }
            return data.map(innerArray => innerArray.map(item => item.starttime || ''));
        }

        function convertToDayStrings(data?: DayClusterData[][]): string[][] {
            if (!data) {
                return [];
            }
            return data.map(innerArray => innerArray.map(item => item.day || ''));
        }

        const getTimeClusterInfo = (index: number): string => {
            const timeStrings = convertToTimeStrings(getActiveTimeClusters())
            if (timeStrings[index].length === 0) {
                return '';
            }
            if (timeStrings[index].length === 1) {
                return timeStrings[index][0];
            }
            return `${timeStrings[index][0]} - ${timeStrings[index][timeStrings[index].length - 1]}`;
        }

        const dayClusterTexts = computed(() => {
            const dayStrings = linePlanningSettings.value.day_clusters

            return dayStrings.map(cluster => {
                const daysMapped = cluster.map(day => dayMapper[day.day?.toLowerCase() || ''] || '');

                if (daysMapped.length === 1) {
                    return daysMapped[0]; // Return the single day
                }

                else {
                    return daysMapped[0] + '-' + daysMapped[daysMapped.length - 1]; // Return the first and last day
                }
            });
        });

        const getActiveTimeClusters = (): TimeClusterData[][] | undefined => {
            if (!linePlanningSettings.value.day_clusters) {
                throw new Error("There is no active day cluster, this should not be possible");
            }
            const activeDaysCluster = linePlanningSettings.value.day_clusters[activeDayCluster.value];
            const activeDays = activeDaysCluster.map((dayObj) => dayObj.day).join("_");

            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("there are no timeclusters according to typescript");
            }

            for (let i = 0; i < linePlanningSettings.value.time_clusters.length; i++) {
                const cluster = linePlanningSettings.value.time_clusters[i];
                if (cluster.day_cluster === activeDays) {
                    return cluster.time_cluster;
                }
            }

            return undefined;
        };


        // Initializing the linePlanningSettings depending on the scenario
        function initializeStandardStops() {
            linePlanningSettings.value.stop_combinations = routeOrder.value;
            linePlanningSettings.value.stop_combinations.forEach((route, index) => {
                if (!linePlanningSettings.value.stop_combinations) {
                    throw new Error("No linePlanningSetti");
                }
                if (!route.id) {
                    throw new Error("There is a route id missing");
                }
                const idParts = route.id.split('_');
                const baseId = idParts.slice(0, -1).join('_');
                route.prediction_id = `${baseId}_${route.id_userstopcode_combination}`;
                let stopParts = route.prediction_id.split('_')
                let testStop = stopParts[stopParts.length - 1]
                if (index === 0) {
                    route.type = 'starthalte';
                } else if (index === linePlanningSettings.value.stop_combinations.length - 1) {
                    route.type = 'eindhalte';
                } else if (route.id_userstopcode_combination?.split('_')[0] !== route.id_userstopcode_combination?.split('_')[1]) {
                    route.type = 'rijtijd'
                } else if (!predictedStops.value.includes(testStop as string)) {
                    route.type = 'missing';
                } else if (route.istimingstop) {
                    route.type = 'meethalte';
                } else {
                    route.type = 'tijdhalte';
                }
                route.active = ['starthalte', 'eindhalte', 'meethalte'].includes(route.type as string);
            });
        }

        function determineDaysData(data: Bus[]): Day[] {
            const dayMap: { [key: string]: { totalTime: number; count: number } } = {};
            data.forEach(bus => {
                const day = bus.day;
                if (day) {
                    if (!dayMap[day]) {
                        dayMap[day] = { totalTime: 0, count: 0 };
                    }
                    if (bus.total_time) {
                        dayMap[day].totalTime += bus.total_time;
                        dayMap[day].count += 1;
                    }
                }
            });
            const result: Day[] = Object.keys(dayMap).map(day => ({
                day,
                average_time: dayMap[day].totalTime / dayMap[day].count,
            }));
            let activeWeekdays: { day: string }[] = [];
            let activeWeekend: { day: string }[] = [];
            let week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            result.sort((a, b) => week.indexOf(a.day as string) - week.indexOf(b.day as string));
            let weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
            let weekend = ['Saturday', 'Sunday']
            result.forEach(day => {
                if (!day.day) {
                    throw new Error("unknown day!")
                }
                if (weekdays.includes(day.day)) {
                    activeWeekdays.push({ day: day.day })
                } else if (weekend.includes(day.day)) {
                    activeWeekend.push({ day: day.day })
                }
            })
            linePlanningSettings.value.day_clusters = [];
            if (activeWeekdays.length !== 0) {
                linePlanningSettings.value.day_clusters.push(activeWeekdays)
            }
            if (activeWeekend.length !== 0) {
                linePlanningSettings.value.day_clusters.push(activeWeekend)
            }
            return result;
        }

        function addClusterIdToSettings() {
            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("cannot call cluster id function on empty settings");
            }
            const identifiers: string[] = [];
            linePlanningSettings.value.time_clusters.forEach(timeCluster => {
                const dayString = timeCluster.day_cluster;
                timeCluster.time_cluster.forEach(timeArray => {
                    const timeString = timeArray.map(time => time.starttime).join('_');
                    identifiers.push(`${dayString}_${timeString}`);
                })
            });
            linePlanningSettings.value.cluster_id = identifiers;
        }

        const handleUpdateStops = (routeOrderRef: Route[]) => {
            linePlanningSettings.value.stop_combinations = routeOrderRef;
            chosenMemory.value = [];
            predictionTrigger.value += 1;
        }

        const handleUpdateDays = async (dayCluster: DayData[][]) => {
            showPredictionTable.value = false;
            linePlanningSettings.value.day_clusters = dayCluster;
            if (!loadedTimeClustersFromDb.value) {
                linePlanningSettings.value.time_clusters = [];
                timeClusterAvailable.value = false;
                activeDayCluster.value = 0;
                handleReloadBarChartLine();
                addClusterIdToSettings();
                runClusterAndAverage();
            }
            loadedTimeClustersFromDb.value = false;
        }

        const handleReloadBarChartLine = () => {
            reloadBarChartTrigger.value += 1
        }

        const handleUpdateTimeCluster = (timeCluster: TimeClusterData[][]) => {
            if (!linePlanningSettings.value.day_clusters) {
                throw new Error("Undefined day_clusters");
            }
            const activeDaysCluster = linePlanningSettings.value.day_clusters?.[activeDayCluster.value];
            const activeDays = activeDaysCluster
                ? activeDaysCluster.map(dayObj => dayObj.day).join('_')
                : '';

            if (!linePlanningSettings.value.time_clusters) {
                linePlanningSettings.value.time_clusters = [];
            }
            const existingIndex = linePlanningSettings.value.time_clusters.findIndex(
                (cluster) => cluster.day_cluster === activeDays
            );
            if (existingIndex !== -1) {
                linePlanningSettings.value.time_clusters[existingIndex].time_cluster = timeCluster;
            } else {
                linePlanningSettings.value.time_clusters.push({
                    day_cluster: activeDays,
                    time_cluster: timeCluster
                });
            }
            activeTimeCluster.value = 0;
            addClusterIdToSettings();
        };


        // Function for clustering and averaging data based on chosen stops for analysis, tricky part of the code...
        function clusterData(data: PREDICTION[], timeClusters: string[][], dayClusters: string[][]): AveragedPrediction[] {
            const grouped: AveragedPrediction[] = [];
            timeClusters.forEach((timeCluster) => {
                dayClusters.forEach(dayCluster => {
                    const matchedData = data.filter(d => {
                        const time = getTimeFromId(d.id);
                        const day = getDayFromId(d.id);
                        return timeCluster.includes(time) && dayCluster.includes(day);
                    });
                    if (matchedData.length) {
                        const newId = matchedData.map(d => d.id).join('__');
                        const newPredictions = matchedData.flatMap(d => d.single_prediction);
                        grouped.push({
                            id: newId,
                            timeCluster: timeCluster.join("_"),
                            dayCluster: dayCluster.join("_"),
                            single_prediction: newPredictions as Block[]
                        });
                    }
                });
            });
            return grouped;
        }


        const runClusterAndAverage = (): void => {
            if (!isLoading.value) {
                try {
                    // Prediction data
                    const clusteredData = clusterData(
                        allPredictions.value,
                        convertToTimeStrings(getActiveTimeClusters()),
                        convertToDayStrings(linePlanningSettings.value.day_clusters)
                    );
                    const averagedData = applyAverageToPredictions(clusteredData);

                    combinedPredictions.value = averagedData;

                    // Schedule data
                } catch (error) {
                    console.error("Error during clustering and averaging:", error);
                }
            }
        };

        function averageValuesPrediction(predictions: Block[]): Block[] {
            const grouped: Record<string, Block[]> = {};
            predictions.forEach(prediction => {
                if (prediction.id) {
                    if (!grouped[prediction.id]) {
                        grouped[prediction.id] = [];
                    }
                    grouped[prediction.id].push(prediction);
                }
            });
            return Object.keys(grouped).map(id => {
                const blocks = grouped[id];
                const keys = Object.keys(blocks[0]) as (keyof Block)[];
                const averaged: Partial<Block> = { id: blocks[0].id } as Partial<Block>;
                keys.forEach(key => {
                    if (key !== 'id') {
                        const sum = blocks.reduce((acc, block) => {
                            const value = block[key] !== null && !isNaN(block[key] as number) ? (block[key] as number) : 0;
                            return acc + value;
                        }, 0);
                        (averaged as any)[key] = sum / blocks.length;
                    }
                });
                return averaged as Block;
            });
        }

        function applyAverageToPredictions(predictionDicts: AveragedPrediction[]): AveragedPrediction[] {
            return predictionDicts.map(predictionDict => ({
                id: predictionDict.id,
                timeCluster: predictionDict.timeCluster,
                dayCluster: predictionDict.dayCluster,
                single_prediction: predictionDict.single_prediction ? averageValuesPrediction(predictionDict.single_prediction) : []
            }));
        }

        // Code for handling some of the UI buttons and animations
        const fullReset = () => {
            try {
                chosenMemory.value = [];
                const baseMessage = 'De gekozen tijden zijn gereset voor lijn: ';
                resetMessage.value = baseMessage.concat(props.lineId);
                messageType.value = 'good';
                setTimeout(() => {
                    resetMessage.value = '';
                }, 3000);
            } catch (err) {
                console.error("Error message: ", err);
                messageType.value = 'bad';
                const baseMessage = "Wijzigingen niet opgeslagen, error: ";
                resetMessage.value = baseMessage.concat(err as string);
            }
        }

        const toggleCollapseChooseStops = () => {
            collapseChooseStops.value = !collapseChooseStops.value
        }

        const toggleCollapseChooseDays = () => {
            collapseChooseDays.value = !collapseChooseDays.value
        }

        const messageClass = computed(() => {
            if (route.params.messagetype === 'bad') {
                return 'bg-red-200';
            }
            if (route.params.messagetype === 'good') {
                return 'bg-green-200';
            } else {
                return 'bg-green-200';
            }
        });

        const handleDayClusterClick = async (index: number) => {
            activeDayCluster.value = index;
            activeTimeCluster.value = 0;
        }
        const timeClusterTrigger = ref<number>(0);
        const handleTimeClusterClick = (index: number) => {
            activeTimeCluster.value = index;
            timeClusterTrigger.value += 1;
        }
        const timeClusterAvailable = ref<boolean>(false)
        // write some code to check that the timecluster is actually available, which is set through linePlanningSettings
        const handleBarchartFinished = () => {
            timeClusterAvailable.value = true;
            if (!isLoading.value && dayClustersAvailable.value) {
                runClusterAndAverage();
                predictionTrigger.value += 1
                showPredictionTable.value = true;
            }
        }
        const dayClustersAvailable = ref<boolean>(true)
        const handleDaychartFinished = () => {
            dayClustersAvailable.value = true;
            if (!isLoading.value && timeClusterAvailable.value) {
                showPredictionTable.value = true;
            }
        }

        watch(() => isLoading.value, async () => {
            if (timeClusterAvailable.value && dayClustersAvailable.value) {
                runClusterAndAverage();
                showPredictionTable.value = true;
            }
        }, { deep: true });

        watch(() => linePlanningSettings.value.time_clusters, async () => {
            timeClusterAvailable.value = true;
            runClusterAndAverage();
            await nextTick();
            if (!isLoading.value && timeClusterAvailable.value && dayClustersAvailable.value) {
                showPredictionTable.value = true;
            }
            predictionTrigger.value += 1
        }, { deep: true })


        watch(() => linePlanningSettings.value.stop_combinations, () => {
            if (!loadedTimeClustersFromDb.value) {
                runClusterAndAverage();
                predictionTrigger.value += 1
                //chosenMemory.value = [];
            }
        }, { deep: true });

        // functions om naar de HomePage terug te routen
        const router = useRouter();

        const returnToHome = () => {
            router.push({ name: 'HomePage' })
        }

        return {
            message,
            returnToHome,
            messageClass,
            fullReset,
            resetMessage,
            tripsData,
            fetchRoute,
            routeOrder,
            toggleCollapseChooseStops,
            collapseChooseStops,
            handleUpdateStops,
            handleUpdateTimeCluster,
            linePlanningSettings,
            toggleCollapseChooseDays,
            collapseChooseDays,
            daysData,
            handleUpdateDays,
            dayClusterTexts,
            handleDayClusterClick,
            activeDayCluster,
            handleTimeClusterClick,
            activeTimeCluster,
            combinedPredictions,
            showPredictionTable,
            getTimeClusterInfo,
            isLoading,
            userNameLocalOrProps,
            getActiveTimeClusters,
            lineNameLocalOrProps,
            journeyPatternCodeLocalOrProps,
            linePublicNumberLocalOrProps,
            updateChosenTimes,
            chosenMemory,
            saveToServer,
            handleLoadButton,
            loadDropdown,
            sessionList,
            selectSession,
            saveMessage,
            reloadBarChartTrigger,
            predictedStops,
            predictionTrigger,
            handleBarchartFinished,
            handleDaychartFinished,
            timeClusterTrigger,
            togglePercentielen,
            selectPercentiel,
            selectedPercentiel,
            isPercentielenOpen,
            percentielen,
            tripsDataAvailable,
            barChartDayTrigger,
            lineSchedule,
            allPredictions
        }
    },
}) 
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: height 0.1s ease, opacity 0.1s ease;
}

.slide-enter,
.slide-leave-to

/* .slide-leave-active in <2.1.8 */
    {
    height: 10;
    opacity: 0;
    overflow: hidden;
}

.block-route-cluster {
    height: 100%;
    /* Ensure the parent takes full height */
    overflow: hidden;
    /* Prevent scrolling on the parent */
    display: flex;
    flex-direction: column;
}

.scrollable-content {
    flex: 1 1 auto;
    /* Allow the child to grow and shrink */
    overflow-y: auto;
    /* Enable vertical scrolling on the child */
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
}

.active-day-cluster {
    @apply bg-white
}

.active-time-cluster {
    @apply bg-white
}

.scrollbar-none::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-none {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
</style>