// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import SingleLijnSchedule from '../views/SingleLijnSchedule.vue';
import HomePage from '../views/HomePage.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {title: 'Home'}
  },
  {
    path: '/lijn-planner/:lineId',
    name: 'SingleLijnSchedule',
    component: SingleLijnSchedule,
    meta: {title: 'Lijn planner'},
    props: (route: RouteLocationNormalized) => ({
      lineId: route.params.lineId,
      journeyPatternCode: route.params.journeyPatternCode,
      linePublicNumber: route.params.linePublicNumber,
    })
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard to update the title
router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string || 'HIPE';
  next();
});

export default router;