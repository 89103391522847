<template>
  <div class="overflow-auto flex-grow">
    <div ref="timeClusterInfoDiv" class="time-cluster-info w-full flex justify-between items-center px-4">
      <div class="flex w-3/4 bottom-0">
        <div class="text-sm mr-2 rounded-full bg-blue-200 shadow-md p-1 text-center w-1/4">{{ dayClusterInfo }}</div>
        <div class="text-sm mr-2 rounded-full bg-blue-200 shadow-md p-1 text-center w-1/4">{{ timeClusterInfo }}</div>
        <div v-if="dayClusterInfoLength * timeClusterInfoLength !== 1"
          class="text-sm mr-2 rounded-full bg-blue-200 shadow-md p-1 text-center w-1/4">{{ dayClusterInfoLength *
          timeClusterInfoLength }} bussen</div>
        <div v-else class="text-sm mr-2 rounded-full bg-blue-200 shadow-md p-1 text-center w-1/4">{{
          dayClusterInfoLength * timeClusterInfoLength }} bus</div>
      </div>
      <button @click="toggleCollapseTable"
        class="w-36 bg-gray-200 hover:bg-gray-300 text-black text-sm py-2 px-1 rounded my-1 flex items-center right-0">
        <svg v-if="collapseTable" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
          viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
        <span>{{ collapseTable ? "Tabel uitvouwen" : "Tabel inklappen" }}</span>
      </button>
    </div>
    <div class="flex-grow flex-col w-full px-4 py-2 flex">
      <div class="relative overflow-hidden shadow-md sm:rounded-lg flex-grow flex flex-col">
        <div class="flex-grow overflow-auto" :style="{ 'height': 'auto', 'max-height': `${availableTableHeight}px` }">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
              <tr>

                <th v-if="collapseTable" scope="col" class="px-6 py-3">Trajectdeel</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3">Traject/Halte</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3">Aankomst type</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3">Type</th>

                <th v-if="collapseTable" scope="col" class="px-6 py-3">Dienstregeling 2023</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3">Voorspeld op traject</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3">Gekozen op traject</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3">% Op tijd</th>

                <th v-if="!collapseTable" scope="col" class="px-6 py-3">Dienstregeling 2023</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3">Voorspeld voor rij-/haltetijd</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3">Gekozen halte- rijttijd</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3">% Op tijd</th>

              </tr>
            </thead>
            <tbody class="overflow-y-auto">
              <tr v-for="(item, index) in displayedStopCombinations" :key="index" :class="computeRowClass(item, index)"
                class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800">
                <td v-if="collapseTable" class="px-6 py-4">
                  {{ item.label }}
                </td>
                <td v-if="!collapseTable" class="px-6 py-4">
                  {{ item.id_userstopname_combination?.replace('-', " — ") }}
                </td>
                <td class="px-6 py-4">
                  {{ item.type }}
                </td>

                <!-- This is visible when the table is collapsed, so summed and averaged values -->

                <td v-if="collapseTable" class="px-6 py-4">
                  <span v-if="item.type === 'starthalte'">---</span>
                  <span v-else>
                    {{ predictionLookup[item.track_id as string]?.schedulesum }}</span>
                </td>

                <!-- Predicted time sum -->
                <td v-if="collapseTable" class="px-6 py-4">
                  <span>{{
          secondsToMMSS(predictionLookup[item.track_id as string]?.sum.toFixed(0)) }}
                  </span>
                </td>

                <!-- Chosen time sum -->
                <td v-if="collapseTable" class="px-6 py-4">
                  <form v-if="item.type !== 'starthalte'" class="bg-white rounded shadow-md w-16"
                    @submit.prevent="handleSubmit">
                    <div class="flex">
                      <input type="text" :value="secondsToMMSS(getChosenTimeSum(item.track_id as string))"
                        @blur="event => { formatTimeInput(event, secondsToMMSS(getChosenTimeSum(item.track_id as string))); updateChosenTimeSum(item.track_id as string, (event.target as HTMLInputElement).value) }"
                        @keydown.enter="handleEnter" id="gekozen-tijd" name="gekozen-tijd"
                        class="w-16 p-1 text-sm border border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      <button class="mr-2" type="button" @mousedown="startIncrementSum(item.track_id as string, 5)"
                        @mouseleave="stopIncrement()" @mouseup="stopIncrement()">▲</button>
                      <button type="button" @mousedown="startIncrementSum(item.track_id as string, -5)"
                        @mouseleave="stopIncrement()" @mouseup="stopIncrement()">▼</button>
                    </div>
                  </form>
                  <span v-else-if="item.type === 'starthalte'">---</span>
                  <span v-else>{{ secondsToMMSS(getChosenTimeSum(item.track_id as string)) }}</span>
                </td>


                <td v-if="collapseTable" class="px-6 py-4 w-32">
                  <form v-if="item.type !== 'starthalte'" @submit.prevent="handleSubmit">
                    <div class="flex">
                      <input type="text" :value="getChosenPercentageSum(item.track_id as string)" @blur="event => {
          formatPercentInput(event);
          updateChosenPercentSum(item.track_id as string, parseFloat((event.target as HTMLInputElement).value))
        }" id="gekozen-tijd" @keydown.enter="handleEnter" name="gekozen-tijd"
                        class="w-16 p-1 text-sm border bg-white shadow-md border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      <span>%</span>
                      <button :class="determineButtonClassUp(Math.round(50))" class="mr-2" type="button"
                        @mousedown="startIncrementPercentageSum(item.track_id as string, 1)"
                        @mousemove="stopIncrementPercentageSum()" @mouseup="stopIncrementPercentageSum()">▲</button>
                      <button :class="determineButtonClassDown(Math.round(50))" type="button"
                        @mousedown="startIncrementPercentageSum(item.track_id as string, -1)"
                        @mousemove="stopIncrementPercentageSum()" @mouseup="stopIncrementPercentageSum()">▼</button>
                    </div>
                  </form>
                  <span v-else-if="item.type === 'starthalte'">---</span>
                  <span v-else>Missing data</span>
                </td>


                <!-- This is visible when the table is expanded -->
                <td v-if="!collapseTable">
                  <span v-if="item.type === 'starthalte' || item.type === 'eindhalte'">---</span>
                  <span v-else>
                    {{ getSchedule(item.prediction_id as string) }}
                  </span>
                </td>


                <td v-if="!collapseTable" class="px-6 py-4">
                  <span v-if="item.type === 'starthalte' || item.type === 'eindhalte'">---</span>
                  <span v-else-if="checkAvailableData(item.prediction_id as string) && item.type === 'rijtijd'">
                    {{ secondsToMMSS(Math.round(predictionLookup[item.prediction_id as string]?.time as number)) }}
                  </span>
                  <span v-else>Missing Data</span>
                </td>



                <td v-if="!collapseTable" class="px-6 py-4">
                  <!--- This one should be able in the standard case-->
                  <span v-if="checkAvailableData(item.prediction_id as string)">{{
          secondsToMMSS(getChosenTimeBlock(item.prediction_id as string)) }}</span>
                  <span v-else>Error, should be data</span>
                </td>


                <td v-if="!collapseTable" class="px-6 py-4 w-32">
                  <span v-if="checkAvailableData(item.prediction_id as string)">
                    {{ getChosenPercentageBlock(item.prediction_id as string).toFixed(0) }}
                  </span>
                  <span v-else>Missing data</span>
                </td>

              </tr>
              <tr v-if="collapseTable" class="bg-blue-100 font-bold">
                <td class="px-6 py-4">
                  {{ beginAndEndStop }}
                </td>
                <td class="px-6 py-4">Totaal</td>
                <td class="px-6 py-4">{{ totalSchedule }}</td>
                <td class="px-6 py-4">{{ secondsToMMSS(Math.round(totalsum)) }}</td>
                <td class="px-6 py-4">{{ secondsToMMSS(Math.round(totalsumchosen)) }}</td>
                <td class="px-6 py-4">{{ totalPercentageChosen.toFixed(0) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from "vue";
import {
  Route,
  LinePlanningSettings,
  TimeClusterData,
  DayClusterData,
  AveragedPrediction,
  Block,
  Chosen,
  ChosenMemory,
  PercentilePredictions,
  ClusteredSchedule,
  LineSchedule,
} from "@/types";

export default defineComponent({
  name: "PredictionTable",
  emits: ["update-chosen-times"],
  props: {
    route: {
      type: Array as () => Route[],
      required: true,
    },
    percentiel: {
      type: Number,
      required: true
    },
    predictions: {
      type: Array as () => AveragedPrediction[],
      required: true,
    },
    schedule: {
      type: Array as () => LineSchedule[],
      required: true,
    },
    activeTimeCluster: {
      type: Number,
      required: true,
    },
    timeClusterTrigger: {
      type: Number,
      required: true,
    },
    activeDayCluster: {
      type: Number,
      required: true,
    },
    linePlanningSettings: {
      type: Object as PropType<LinePlanningSettings>,
      required: true,
    },
    chosenMemory: {
      type: Array as PropType<ChosenMemory[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Responsive data variables
    const timeClusterInfo = ref<string>("");
    const timeClusterInfoLength = ref<number>(0);
    const dayClusterInfo = ref<string>("");
    const dayClusterInfoLength = ref<number>(0);
    const timeIdentifier = ref<string>("");
    const dayIdentifier = ref<string>("");
    const journeyIdentifier = ref<string>("");
    const dayMapper: { [key: string]: string } = {
      monday: "Maandag",
      tuesday: "Dinsdag",
      wednesday: "Woensdag",
      thursday: "Donderdag",
      friday: "Vrijdag",
      saturday: "Zaterdag",
      sunday: "Zondag",
    };

    const lineNameLocal = ref<string | null>(localStorage.getItem('lineName'));
    const journeyPatternCodeLocal = ref<string | null>(localStorage.getItem('journeyPatternCode'));
    const linePublicNumberLocal = ref<string | null>(localStorage.getItem('linePublicNumber'));

    const currentPrediction = ref<AveragedPrediction>();
    const aggregatedPrediction = ref<AveragedPrediction>();
    const chosenTimesSum = ref<Chosen[]>([]);
    const chosenTimesBlock = ref<Chosen[]>([]);
    const singleTimeCluster = ref<TimeClusterData[][]>([]);
    const timeClusterInfoDiv = ref<HTMLElement | null>(null);
    const availableTableHeight = ref<number>(0);
    const clusteredSchedule = ref<ClusteredSchedule[]>([]);
    const totalSchedule = ref<string>("");

    const totalsum = ref<number>(0)
    const totalsumchosen = ref<number>(0)
    const totalPercentageChosen = ref<number>(50);

    const standardPercentage = ref<string>((props.percentiel / 100).toFixed(1))

    // State management
    const collapseTable = ref<boolean>(true);

    // Functions for calculating the layout of the table
    const calculateAvailableTableHeigth = () => {
      if (timeClusterInfoDiv.value) {
        const rect = timeClusterInfoDiv.value.getBoundingClientRect();
        availableTableHeight.value = window.innerHeight - rect.bottom - 64;
      }
    };

    // Helper functions for finding and converting identifiers
    const updateTimeDayIdentifiers = () => {
      const days = convertSettingsToFullDayStrings(
        props.linePlanningSettings.day_clusters
      )[props.activeDayCluster];
      dayIdentifier.value = days.join("_");

      const times = props.linePlanningSettings.time_clusters?.find(
        (cluster) => cluster.day_cluster === dayIdentifier.value
      );
      if (!times) {
        throw new Error("There is no time cluster for this day cluster");
      }
      singleTimeCluster.value = times.time_cluster;
      timeIdentifier.value = times.time_cluster[props.activeTimeCluster]
        .map((timeItem) => timeItem.starttime)
        .join("_");
      journeyIdentifier.value = times.time_cluster[props.activeTimeCluster]
        .map((timeItem) => timeItem.journeynumber)
        .join("_");
    };

    function convertSettingsToTimeStrings(data?: TimeClusterData[][]): string[][] {
      if (!data) {
        return [];
      }
      return data.map((innerArray) => innerArray.map((item) => item.starttime || ""));
    }

    function convertSettingsToFullDayStrings(data?: DayClusterData[][]): string[][] {
      if (!data) {
        return [];
      }
      return data.map((innerArray) => innerArray.map((item) => item.day || ""));
    }

    const formatTimeInput = (event: Event, originalValue: string) => {
      const input = event.target as HTMLInputElement;
      let value = input.value.replace(/[^0-9:]/g, ""); // Allow only numbers and colon

      if (!value.includes(":") && value.length > 2) {
        value = value.slice(0, -2) + ":" + value.slice(-2);
      }

      const parts = value.split(":");
      if (parts.length === 2) {
        const minutes = parts[0];
        const seconds = parts[1];

        if (seconds.length > 2 || parseInt(seconds) >= 60) {
          alert("Invalid time format. Please enter a valid time in MM:SS format.");
          input.value = originalValue;
          return;
        }

        if (minutes.length > 0 && seconds.length === 2) {
          input.value = value;
        } else {
          input.value = originalValue;
        }
      } else {
        input.value = originalValue;
      }
    };

    const secondsToMMSS = (seconds: number) => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const mmssToSeconds = (mmss: string) => {
      const [mins, secs] = mmss.split(":").map(Number);
      return mins * 60 + secs;
    };

    const hhmmssToSeconds = (hhmmss: string): number => {
      const [h, m, s] = hhmmss.split(':').map(Number);
      return h * 3600 + m * 60 + s
    }

    const timer = ref<number>(0);
    const speed = ref<number>(300);
    const speedMultiplier = ref<number>(1.1);

    const determineButtonClassUp = (value: number) => {
      if (value >= 99) {
        return 'text-gray-200'
      } else {
        return ''
      }
    }

    const determineButtonClassDown = (value: number) => {
      if (value <= 1) {
        return 'text-gray-200'
      } else {
        return ''
      }
    }

    // Functions for updating the settings and the chosen times
    const updateAggregatedPrediction = () => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("No lineplanninginfo on the selected stops");
      }
      const rawPrediction = currentPrediction.value;
      if (!rawPrediction) {
        aggregatedPrediction.value = {
          id: "loading",
          timeCluster: "loading",
          dayCluster: "loading",
          single_prediction: []
        }
      } else {
        const sortedPrediction = sortRoute(
          props.linePlanningSettings.stop_combinations,
          rawPrediction
        );
        clusteredSchedule.value = clusterSchedule(props.schedule, timeIdentifier.value, dayIdentifier.value, journeyIdentifier.value);
        totalSchedule.value = calculateTotalSchedule();

        const activeStops = getActiveStops(props.linePlanningSettings.stop_combinations);
        const sortedActivePrediction = determineActiveStops(sortedPrediction, activeStops);
        aggregatedPrediction.value = calculateSumValues(sortedActivePrediction);
        calculateTotalSum(aggregatedPrediction.value);
        calculateTotalChosen();
        calculatePercentagesForAllChosenTimes();
      }
    };

    function getDayFromScheduleId(id: string): string {
      return id.split('_')[4];
    }

    function getJourneyNumberFromId(id: string): string {
      return id.split('_')[3]
    }

    function clusterSchedule(data: LineSchedule[], timeCluster: string, dayCluster: string, journeyCluster: string): ClusteredSchedule[] {
      const grouped: ClusteredSchedule[] = [];
      const journeyClusterArray = journeyCluster.split('_')
      const dayClusterArray = dayCluster.split('_')
      const matchedData = data.filter(d => {
        const journeyNumber = getJourneyNumberFromId(d.id);
        const day = getDayFromScheduleId(d.id);
        return journeyClusterArray.includes(journeyNumber) && dayClusterArray.includes(day);
      });
      if (matchedData.length) {
        const newId = matchedData.map(d => d.id).join('__');
        const stopTimeRanges: { [id_userstop_combination: string]: string } = {};
        // Get all seconds from matchedData
        let minScheduleLine: number | null = null;
        let maxScheduleLine: number | null = null;
        matchedData.forEach(lineSchedule => {
          let minTotal = 0;
          let maxTotal = 0;
          lineSchedule.schedule.forEach(stop => {
            if (!stop.id_userstop_combination) {
              throw new Error("NO stop combination")
            }
            const userstopcode = stop.id_userstop_combination;
            // If this userstopcode is not in the object, initialize it
            const seconds = hhmmssToSeconds(stop.time_planned as string);
            if (!stopTimeRanges[userstopcode]) {
              stopTimeRanges[userstopcode] = `${seconds}-${seconds}`;
            } else {
              // Parse the existing min-max
              const [min, max] = stopTimeRanges[userstopcode].split('-').map(Number);

              // Update the min and max for this userstopcode
              const newMin = Math.min(min, seconds);
              const newMax = Math.max(max, seconds);
              stopTimeRanges[userstopcode] = `${newMin}-${newMax}`;
            }

            minTotal += stopTimeRanges[userstopcode].split('-').map(Number)[0];
            maxTotal += stopTimeRanges[userstopcode].split('-').map(Number)[1];
          });
          if (minScheduleLine && maxScheduleLine) {
            minScheduleLine = minTotal < minScheduleLine ? minTotal : minScheduleLine;
            maxScheduleLine = maxTotal > maxScheduleLine ? maxTotal : maxScheduleLine;
          } else {
            minScheduleLine = minTotal;
            maxScheduleLine = maxTotal;
          }
        });
        const timeRangeArray = Object.entries(stopTimeRanges).map(([userstopcode, range]) => {
          return { [userstopcode]: range };
        });
        grouped.push({
          id: newId,
          time_cluster: timeCluster,
          day_cluster: dayCluster,
          journey_cluster: journeyCluster,
          total_schedule: `${minScheduleLine}-${maxScheduleLine}`,
          time_range: timeRangeArray
        });
      }
      return grouped;
    }

    const incrementTimeSum = (id: string, amount: number) => {
      const chosenTimeSum = chosenTimesSum.value.find(item => item.blockId === id);
      if (chosenTimeSum) {
        if (!chosenTimeSum.input) {
          throw new Error("There is no input available for this value");
        }
        chosenTimeSum.input = Math.max(mmssToSeconds(secondsToMMSS(chosenTimeSum.input)) + amount, 0);
        const formattedTime = secondsToMMSS(chosenTimeSum.input);
        updateChosenTimeSum(id, formattedTime);
      }
    };

    const startIncrementSum = (id: string, amount: number) => {
      if (timer.value) return;

      const repeatIncrement = () => {
        incrementTimeSum(id, amount);
        speed.value = Math.max(speed.value / speedMultiplier.value, 10); // Decrease interval time to increase speed
        timer.value = setTimeout(repeatIncrement, speed.value);
      };

      // Start the first increment
      incrementTimeSum(id, amount);

      // Start the repeating increment
      timer.value = setTimeout(repeatIncrement, speed.value);

      // Add a global mouseup event to ensure stopIncrement is called if mouseup occurs outside the button
      window.addEventListener('mouseup', stopIncrement);
    };

    const stopIncrement = () => {
      clearTimeout(timer.value);
      timer.value = 0;
      speed.value = 300; // Reset speed
      calculateTotalChosen();
      calculatePercentagesForAllChosenTimes();
      // Remove the global mouseup event listener
      window.removeEventListener('mouseup', stopIncrement);
    };

    const incrementPercentageSum = (id: string, amount: number) => {
      const chosenTimeSum = chosenTimesSum.value.find(item => item.blockId === id);
      if (chosenTimeSum) {
        if (chosenTimeSum.percentage === undefined) {
          chosenTimeSum.percentage = 0; // Default to 0 if no percentage is set
        }

        // Update the percentage by the specified amount, ensuring it stays within 0-100%
        chosenTimeSum.percentage = Math.max(0, Math.min(chosenTimeSum.percentage + amount, 100));

        // Calculate and update the corresponding time based on the new percentage
        updateChosenPercentSum(id, chosenTimeSum.percentage);
      } else {
        console.error(`Could not find chosenTimeSum entry for id: ${id}`);
      }
    };

    const startIncrementPercentageSum = (id: string, amount: number) => {
      if (timer.value) return;

      const repeatIncrement = () => {
        incrementPercentageSum(id, amount);
        speed.value = Math.max(speed.value / speedMultiplier.value, 10); // Decrease interval time to increase speed
        timer.value = setTimeout(repeatIncrement, speed.value);
      };

      // Start the first increment
      incrementPercentageSum(id, amount);

      // Start the repeating increment
      timer.value = setTimeout(repeatIncrement, speed.value);

      // Add a global mouseup event to ensure stopIncrementPercentageSum is called if mouseup occurs outside the button
      window.addEventListener('mouseup', stopIncrementPercentageSum);
    };

    const stopIncrementPercentageSum = () => {
      clearTimeout(timer.value);
      timer.value = 0;
      speed.value = 300; // Reset speed
      calculateTotalChosen(); // If you need to update totals after percentage changes
      // Remove the global mouseup event listener
      window.removeEventListener('mouseup', stopIncrementPercentageSum);
    };

    const formatPercentInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      let value = Number(input.value.replace(/[^0-9]/g, "")); // Allow only numbers and colon
      input.value = value.toString();
    };



    const getSumsForId = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; sum: number }[] => {
      // Extract the base ID (everything before the last `_`)
      const baseId = id.substring(0, id.lastIndexOf('_'));
      const times: { percentile: number; sum: number }[] = [];

      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.startsWith(baseId)
        });
        if (timeData && timeData.time) {
          times.push({
            percentile: parseFloat(percentile),
            sum: timeData.sum,
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };

    const getTotalForId = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; sum: number }[] => {
      // Extract the base ID (everything before the last `_`)
      const baseId = id.substring(0, id.lastIndexOf('_'));
      const times: { percentile: number; sum: number }[] = [];

      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.startsWith(baseId)
        });
        if (timeData && timeData.totalsum) {
          times.push({
            percentile: parseFloat(percentile),
            sum: timeData.totalsum,
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };



    const getTimesForId = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; time: number }[] => {
      // Extract the base ID (everything before the last `_`)
      const baseId = id.substring(0, id.lastIndexOf('_'));
      const times: { percentile: number; time: number }[] = [];

      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.startsWith(baseId)
        });
        if (timeData && timeData.time) {
          times.push({
            percentile: parseFloat(percentile),
            time: timeData.time,
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };



    const calculatePercentagesForAllChosenTimes = (): void => {
      // Iterate over each entry in chosenTimesSum
      chosenTimesSum.value.forEach((chosenTime) => {
        if (!aggregatedPrediction.value) {
          throw new Error("There is no aggregatedPrediction")
        }
        if (!aggregatedPrediction.value.single_prediction || !aggregatedPrediction.value.single_predictions) {
          throw new Error("No single prediction or single predictions available");
        }

        const id = chosenTime.blockId;
        // Find the corresponding single prediction by ID

        // Get the times for the given ID
        const times = getSumsForId(id as string, aggregatedPrediction.value.single_predictions);

        if (times.length === 0) {
          console.error(`No times found for the given id: ${id}`);
          return;
        }

        // Calculate the percentage based on the targetTime
        let calculatedPercentage = 0;
        const targetTime = chosenTime.input; // Assuming sum is the time we want to match
        if (!targetTime) {
          throw new Error("There is no chosenTImes")
        }

        if (targetTime <= times[0].sum) {
          calculatedPercentage = times[0].percentile * 100;
        } else if (targetTime >= times[times.length - 1].sum) {
          calculatedPercentage = times[times.length - 1].percentile * 100;
        } else {
          // Perform linear interpolation between the surrounding percentiles
          for (let i = 0; i < times.length - 1; i++) {
            const lower = times[i];
            const upper = times[i + 1];

            if (targetTime >= lower.sum && targetTime <= upper.sum) {
              const timeRange = upper.sum - lower.sum;
              const timeDelta = targetTime - lower.sum;
              const percentileRange = upper.percentile - lower.percentile;
              calculatedPercentage = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

              break;
            }
          }
        }

        // Store the calculated percentage in chosenTimesSum
        chosenTime.percentage = calculatedPercentage;
      });
    };


    const calculatePercentagesForBlockTimes = (): void => {
      // Iterate over each entry in chosenTimesSum
      chosenTimesBlock.value.forEach((chosenTime) => {
        if (!aggregatedPrediction.value) {
          throw new Error("There is no aggregatedPrediction")
        }
        if (!aggregatedPrediction.value.single_prediction || !aggregatedPrediction.value.single_predictions) {
          throw new Error("No single prediction or single predictions available");
        }

        const id = chosenTime.blockId;
        // Find the corresponding single prediction by ID

        // Get the times for the given ID
        const times = getTimesForId(id as string, aggregatedPrediction.value.single_predictions);

        if (times.length === 0) {
          console.error(`No times found for the given id: ${id}`);
          return;
        }

        // Calculate the percentage based on the targetTime
        let calculatedPercentage = 0;
        const targetTime = chosenTime.input; // Assuming sum is the time we want to match
        if (!targetTime) {
          throw new Error("There is no chosenTImes")
        }

        if (targetTime <= times[0].time) {
          calculatedPercentage = times[0].percentile * 100;
        } else if (targetTime >= times[times.length - 1].time) {
          calculatedPercentage = times[times.length - 1].percentile * 100;
        } else {
          // Perform linear interpolation between the surrounding percentiles
          for (let i = 0; i < times.length - 1; i++) {
            const lower = times[i];
            const upper = times[i + 1];

            if (targetTime >= lower.time && targetTime <= upper.time) {
              const timeRange = upper.time - lower.time;
              const timeDelta = targetTime - lower.time;
              const percentileRange = upper.percentile - lower.percentile;
              calculatedPercentage = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

              break;
            }
          }
        }

        // Store the calculated percentage in chosenTimesSum
        chosenTime.percentage = calculatedPercentage;
      });
    };




    const updateChosenPercentSum = (trackId: string, userPercentage: number): void => {
      if (!aggregatedPrediction.value) {
        throw new Error("There is no aggregatedPrediction");
      }
      if (!aggregatedPrediction.value.single_prediction || !aggregatedPrediction.value.single_predictions) {
        throw new Error("No single prediction or single predictions available");
      }

      const times = getSumsForId(trackId, aggregatedPrediction.value.single_predictions);

      if (times.length === 0) {
        console.error(`No times found for the given id: ${trackId}`);
        return;
      }

      // Convert user input percentage to a fraction
      const targetPercentile = userPercentage / 100;

      let calculatedTime = 0;
      let newPercentage = userPercentage;
      if (targetPercentile <= times[0].percentile) {
        calculatedTime = times[0].sum;
        newPercentage = times[0].percentile * 100;
      } else if (targetPercentile >= times[times.length - 1].percentile) {
        calculatedTime = times[times.length - 1].sum;
        newPercentage = times[times.length - 1].percentile * 100;
      } else {
        // Perform inverse interpolation between the surrounding percentiles
        for (let i = 0; i < times.length - 1; i++) {
          const lower = times[i];
          const upper = times[i + 1];

          if (targetPercentile >= lower.percentile && targetPercentile <= upper.percentile) {
            const percentileRange = upper.percentile - lower.percentile;
            const percentileDelta = targetPercentile - lower.percentile;
            const timeRange = upper.sum - lower.sum;
            calculatedTime = lower.sum + (percentileDelta / percentileRange) * timeRange;

            break;
          }
        }
      }

      // Update the input time in chosenTimesSum
      const chosenTime = chosenTimesSum.value.find((item) => item.blockId === trackId);
      if (chosenTime) {
        chosenTime.input = calculatedTime;
        chosenTime.percentage = newPercentage;
      } else {
        console.error("Could not find the chosenTime entry for the provided trackId");
      }

      calculateTotalChosen();
      translateSumToBlock();
    };



    // Functions for instantiating the state on the first load
    const setCurrentPrediction = async () => {
      const prediction = props.predictions.find(
        (entry) =>
          entry.dayCluster == dayIdentifier.value &&
          entry.timeCluster == timeIdentifier.value
        // Add an await next tick here if everything breaks
      );


      if (prediction) {
        if (!prediction.single_prediction) {
          throw new Error("No single prediction available")
        }
        const single_predictions = prediction.single_prediction.reduce((acc, pred) => {

          const percentiel = (pred.id as string).split('_').pop(); // Get the last part of the ID which is the percentage
          if (!acc[percentiel as string]) {
            acc[percentiel as string] = [];
          }
          acc[percentiel as string].push(pred);
          return acc;
        }, {});

        // Now filter the single_predictions based on the standardPercentage
        const filtered_predictions = single_predictions[standardPercentage.value];

        currentPrediction.value = {
          ...prediction,
          single_prediction: filtered_predictions ? filtered_predictions : [],
          single_predictions: single_predictions,  // Store all predictions categorized by percentiles
        };
      }
    };

    // Helper functions for the main calculations of spreading and aggregating chosen values
    const sortRoute = (
      orderedRoute: Route[],
      unorderedPrediction: AveragedPrediction
    ): AveragedPrediction => {
      const orderMap = new Map();
      orderedRoute.forEach((item, index) => {
        orderMap.set(item.prediction_id, index);
      });
      if (!unorderedPrediction.single_prediction) {
        throw new Error("There was no single_prediction available");
      }
      return {
        ...unorderedPrediction,
        single_prediction: unorderedPrediction.single_prediction.sort(
          (a, b) => orderMap.get(a.id) - orderMap.get(b.id)
        ),
      };
    };

    const sortSum = (orderedRoute: Route[], unorderedPrediction: Chosen[]): Chosen[] => {
      // Create a map for quick lookup of the order based on prediction_id
      const orderMap = new Map<string, number>();

      orderedRoute
        .map((item) => {
          const prediction_id = item.prediction_id + '_' + standardPercentage.value
          return {
            ...item,
            prediction_id
          }
        })
        .forEach((item, index) => {
          if (!item.prediction_id) {
            throw new Error("Problem with the prediction_id")
          }
          orderMap.set(item.prediction_id, index);
        });

      if (!unorderedPrediction || unorderedPrediction.length === 0) {
        throw new Error("There was no single_prediction available");
      }

      // Sort the unorderedPrediction array based on the orderMap
      const sortedPredictions = unorderedPrediction.slice().sort((a, b) => {
        if (!a.blockId || !b.blockId) {
          throw new Error("missing id values")
        }
        const indexA = orderMap.get(a.blockId);
        const indexB = orderMap.get(b.blockId);

        if (indexA === undefined || indexB === undefined) {
          console.warn(`Index not found for ids: ${a.blockId} or ${b.blockId}`);
          return 0; // Keep the order of items with undefined indices as is
        }

        return indexA - indexB;
      });


      return sortedPredictions;
    };


    const beginAndEndStop = computed(() => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("no stop combinations provided");
      }
      return props.linePlanningSettings.stop_combinations[0].id_userstopname_combination + ' — ' + props.linePlanningSettings.stop_combinations[props.linePlanningSettings.stop_combinations.length - 1].id_userstopname_combination
    })

    const activeStopCombinations = computed(() => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("no stop combinations provided");
      }
      let previousId = props.linePlanningSettings.stop_combinations[1].prediction_id + '_' + standardPercentage.value;
      let filteredStops: Route[] = [];
      props.linePlanningSettings.stop_combinations.forEach((item) => {
        if (item.id !== undefined && item.active && item.type !== 'rijtijd' && item.type !== 'starthalte') {
          filteredStops.push({
            ...item,
            track_id: previousId, // Save the previous item's ID
          });
        }
        if (!item.id) {
          throw new Error("Missing an item_id for the time between the previous two stops")
        }
        previousId = item.prediction_id as string + '_' + standardPercentage.value; // Update previousId to the current item's ID
      });
      let previousStopName = props.linePlanningSettings.stop_combinations[0].id_userstopname_combination;
      return filteredStops.map((item) => {
        let label = previousStopName + " — " + item.id_userstopname_combination; // Previous stop name + current stop name
        previousStopName = item.id_userstopname_combination ? item.id_userstopname_combination : "Unknown stop"; // Update the previous stop name
        return {
          ...item,
          label
        };
      });
    });


    function getActiveStops(stops?: Route[]): string[] {
      if (!stops) {
        return [];
      }

      const activeStops: string[] = [];
      let previousItem: Route | null = null;

      stops.forEach((item) => {
        if (item.active && item.prediction_id !== undefined) {
          if (previousItem && previousItem.prediction_id !== undefined) {
            // If there is a previous item, set it as active as well
            activeStops.push(previousItem.prediction_id as string);
          }
          // Push the current item's prediction_id to activeStops
          activeStops.push(item.prediction_id as string);
        }
        // Update previousItem for the next iteration
        previousItem = item;
      });

      return activeStops;
    }

    const displayedStopCombinations = computed(() => {
      if (!collapseTable.value) {
        const activeStops = new Set(getActiveStops(props.linePlanningSettings.stop_combinations));
        const filtered = props.linePlanningSettings.stop_combinations?.filter(item => item.type === 'rijtijd');
        return filtered?.map(item => {
          // Create a shallow copy of the item to avoid mutating the original
          const newItem = { ...item };
          newItem.active = activeStops.has(item.prediction_id as string);
          newItem.prediction_id = item.prediction_id + '_' + standardPercentage.value;
          return newItem;
        });
      }
      return activeStopCombinations.value;
    });

    const determineActiveStops = (
      data: AveragedPrediction,
      activeStops: string[],
    ): AveragedPrediction => {
      if (!data.single_prediction && !data.single_predictions) {
        throw new Error("There is no prediction available here");
      }

      // Function to determine active status for a given array of Block objects
      const determineActiveForBlocks = (
        blocks: Block[],
        percentage: string
      ): Block[] => {
        const activeSet = new Set(
          activeStops.map((stop) => `${stop}_${percentage}`)
        );

        return blocks.map((item) => {
          let active = false;
          if (!item.id) {
            active = false; // If there is no prediction for the item, set the active parameter to false
          } else {
            active = activeSet.has(item.id); // Check if the active set includes the id, if so active is set to true
          }

          return { ...item, active };
        });
      };

      // Update single_prediction
      const updatedSinglePrediction = data.single_prediction
        ? determineActiveForBlocks(data.single_prediction, standardPercentage.value)
        : undefined;

      // Update single_predictions (percentiles)
      const updatedSinglePredictions = data.single_predictions
        ? Object.keys(data.single_predictions).reduce((acc, percentile) => {
          if (!data.single_predictions) {
            throw new Error("There is no single_predictions in this currentPrediction")
          }
          acc[percentile] = determineActiveForBlocks(
            data.single_predictions[percentile],
            percentile
          );
          return acc;
        }, {} as PercentilePredictions)
        : undefined;

      return {
        ...data,
        single_prediction: updatedSinglePrediction,
        single_predictions: updatedSinglePredictions,
      };
    };


    const determineActiveStopsChosen = (
      data: Chosen[],
      activeStops: string[]
    ): Chosen[] => {
      const activeSet = new Set(
        activeStops.map((stop) => `${stop}_${standardPercentage.value}`)
      );
      if (!data) {
        throw new Error("There is no prediction available here");
      }
      const activeData = data.map((item) => {
        if (!item.blockId) {
          // If there is no prediction for the item, set the active parameter to false
          const active = false
          return {
            ...item,
            active,
          }
        } else {
          // Check if the active set includes the id, if so active is set to true
          const active = activeSet.has(item.blockId);
          return {
            ...item,
            active,
          };
        }
      });
      return activeData;
    };

    const checkAvailableData = (id: string): boolean => {
      if (!currentPrediction.value?.single_prediction) {
        throw new Error("No current prediction available")
      }
      return currentPrediction.value.single_prediction.some(data => data.id === id);
    }

    const getActiveTimeClusters = (): TimeClusterData[][] | undefined => {
      if (!props.linePlanningSettings.day_clusters) {
        throw new Error("There is no active day cluster, this should not be possible");
      }
      const activeDaysCluster = props.linePlanningSettings.day_clusters[props.activeDayCluster];
      const activeDays = activeDaysCluster.map((dayObj) => dayObj.day).join("_");
      if (!props.linePlanningSettings.time_clusters) {
        throw new Error("there are no timeclusters according to typescript")
      }
      console.log("time_clusters", props.linePlanningSettings.time_clusters)
      const activeTimeCluster = props.linePlanningSettings.time_clusters.find(
        (cluster) => cluster.day_cluster === activeDays
      );
      return activeTimeCluster ? activeTimeCluster.time_cluster : undefined;
    };

    const predictionLookup = computed(() => {
      const map: Record<string, Block> = {};
      if (aggregatedPrediction.value?.single_prediction) {
        aggregatedPrediction.value.single_prediction.forEach((pred) => {
          map[pred.id as string] = pred;
        });
      }
      return map;
    });


    const getClusterId = () => {
      return dayIdentifier.value + "_" + timeIdentifier.value;
    };


    const getSchedule = (id: string): string => {
      console.log("this is the id in getSchedule: ", id)
      const idParts = id.split('_')
      const stopcode = idParts[3] + '_' + idParts[4]

      const activeSchedule = clusteredSchedule.value.find((item) => {
        return item.day_cluster === dayIdentifier.value && item.time_cluster === timeIdentifier.value;
      })

      if (activeSchedule) {
        const foundItem = activeSchedule.time_range?.find((item) => item[stopcode] !== undefined);

        if (foundItem) {
          const minmax = foundItem[stopcode].split('-')
          if (minmax[0] === minmax[1]) {
            return `${secondsToMMSS(parseInt(minmax[0]))}`
          } else {
            return `${secondsToMMSS(parseInt(minmax[0]))}-${secondsToMMSS(parseInt(minmax[1]))}`;
          }

        }

        return 'No schedule'; // Handle the case where nothing is found
      }
      return "work in progress"
    }

    const calculateTotalSchedule = (): string => {
      const timeCluster = getActiveTimeClusters();
      console.log("these are the two parameters: ", timeCluster, props.linePlanningSettings.day_clusters)
      if (!timeCluster || !props.linePlanningSettings.day_clusters) {
        throw new Error("There is no time or day cluster data");
      }
      const timeIdentifier = timeCluster[props.activeTimeCluster]
        .map((time) => time.starttime)
        .join("_");
      const dayIdentifier = props.linePlanningSettings.day_clusters[
        props.activeDayCluster
      ]
        .map((day) => day.day)
        .join("_");

      const activeSchedule = clusteredSchedule.value.find((item) => {
        return item.day_cluster === dayIdentifier && item.time_cluster === timeIdentifier;
      })

      if (activeSchedule && activeSchedule.total_schedule) {
        const minmax = activeSchedule.total_schedule?.split('-');
        if (minmax[0] === minmax[1]) {
          return `${secondsToMMSS(parseInt(minmax[0]))}`
        } else {
          return `${secondsToMMSS(parseInt(minmax[0]))} - ${secondsToMMSS(parseInt(minmax[1]))}`;
        }
      } else {
        return "Missing schedule"
      }
    }

    // Functions for calculating the aggregated and single chosen times, again the very tricky part
    const calculateTotalSum = (data: AveragedPrediction) => {
      let sum = 0;
      if (!data.single_prediction) {
        throw new Error("There is no prediction available here");
      }

      data.single_prediction
        .map((item) => {
          sum += item.time ? item.time : 0;
        })

      totalsum.value = sum;

    }

    const calculateTotalChosen = () => {
      let sumchosen = 0;
      let lastActiveItem: Chosen = {};
      chosenTimesSum.value.forEach((item) => {
        if (item.active) {
          sumchosen += item.input ? item.input : 0;
          lastActiveItem = item;
        }
      });
      totalsumchosen.value = sumchosen;

      if (!lastActiveItem) {
        console.error("No active item found in chosenTimesSum");
        return;
      }
      // Calculate the total percentage
      let totalPercentage = 0;

      // Retrieve all times and percentiles
      const allTimes: { percentile: number; sum: number }[] = [];
      // Ensure aggregatedPrediction is available
      if (!aggregatedPrediction.value) {
        throw new Error("No aggregated predictions");
      }
      if (!aggregatedPrediction.value.single_predictions) {
        throw new Error("No single predictions");
      }

      // Get the times for the last active item's ID
      const times = getTotalForId(lastActiveItem.blockId as string, aggregatedPrediction.value.single_predictions);
      if (times && times.length > 0) {
        allTimes.push(...times);
      } else {
        console.error(`No times found for the given id: ${lastActiveItem.blockId}`);
        return;
      }

      // Sort times by percentile to ensure they are in the correct order for interpolation
      allTimes.sort((a, b) => a.percentile - b.percentile);

      // Safety check: Ensure that the first and last items in allTimes have defined times
      if (typeof allTimes[0]?.sum === 'undefined' || typeof allTimes[allTimes.length - 1]?.sum === 'undefined') {
        console.error("Undefined time value encountered in allTimes");
        return;
      }

      if (sumchosen <= allTimes[0].sum) {
        totalPercentage = allTimes[0].percentile * 100;
      } else if (sumchosen >= allTimes[allTimes.length - 1].sum) {
        totalPercentage = allTimes[allTimes.length - 1].percentile * 100;
      } else {
        // Perform linear interpolation between the surrounding percentiles
        for (let i = 0; i < allTimes.length - 1; i++) {
          const lower = allTimes[i];
          const upper = allTimes[i + 1];

          if (sumchosen >= lower.sum && sumchosen <= upper.sum) {
            const timeRange = upper.sum - lower.sum;
            const timeDelta = sumchosen - lower.sum;
            const percentileRange = upper.percentile - lower.percentile;
            totalPercentage = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;
            break;
          }
        }
      }

      totalPercentageChosen.value = totalPercentage;
    };


    const calculateSumValues = (data: AveragedPrediction): AveragedPrediction => {
      if (!data.single_prediction && !data.single_predictions) {
        throw new Error("There is no prediction available here");
      }

      // Function to calculate sums for a given array of Block objects
      const calculateSumForBlocks = (blocks: Block[], schedule = false): Block[] => {
        let sum = 0;
        let sumActive = 0;
        let totalSum = 0;

        let sumMinSchedule = 0;
        let sumMaxSchedule = 0;
        let sumMinScheduleActive = 0;
        let sumMaxScheduleActive = 0;

        return blocks.map((item) => {
          console.log("This is the item.id: ", item.id)
          if (sum === 0) {
            sumActive = 0;
          }
          if (sumMinSchedule === 0) {
            sumMinScheduleActive = 0;
            sumMaxScheduleActive = 0;
          }
          if (!item.id) {
            return {
              ...item,
              missing: false,
              sum: 0,
              locked: true,
              totalsum: totalSum
            };
          }
          if (!item.time) {
            return {
              ...item,
              sum: sumActive,
              totalsum: totalSum
            };
          }

          // if we also want the schedule run the shedule part
          if (schedule) {
            let minSchedule = 0;
            let maxSchedule = 0;
            const singleSchedule = getSchedule(item.id).split('-').map(item => mmssToSeconds(item))
            if (singleSchedule.length === 1) {
              minSchedule = singleSchedule[0];
              maxSchedule = singleSchedule[0];
            } else {
              minSchedule = singleSchedule[0];
              maxSchedule = singleSchedule[1]; 
            }
            if (item.active) {
              totalSum += item.time
              sumActive += item.time;
              sumMinSchedule = 0;
              sumMaxSchedule = 0;
              sumMinScheduleActive += minSchedule;
              sumMaxScheduleActive += maxSchedule;
              sum = 0;
              let schedulesum = ""
              if (sumMinScheduleActive === sumMaxScheduleActive) {
                schedulesum = secondsToMMSS(sumMinScheduleActive);
              } else {
                 schedulesum = `${secondsToMMSS(sumMinScheduleActive)} - ${secondsToMMSS(sumMaxScheduleActive)}`
              }
              return {
                ...item,
                sum: sumActive,
                totalsum: totalSum,
                schedulesum: schedulesum
              };
            } else {
              sum += item.time;
              totalSum += item.time
              sumActive = sum;
              sumMinSchedule += minSchedule;
              sumMaxSchedule += maxSchedule;
              sumMinScheduleActive = sumMinSchedule;
              sumMaxScheduleActive = sumMaxSchedule;
              return {
                ...item,
                sum: sum,
                totalsum: totalSum,
                schedulesum: `${secondsToMMSS(sumMinScheduleActive)} - ${secondsToMMSS(sumMaxScheduleActive)}`
              };
            }
          }

          // else save some compute by skipping that part
          if (item.active) {
            totalSum += item.time
            sumActive += item.time;
            sum = 0;
            return {
              ...item,
              sum: sumActive,
              totalsum: totalSum
            };
          } else {
            sum += item.time;
            totalSum += item.time
            sumActive = sum;
            return {
              ...item,
              sum: sum,
              totalsum: totalSum
            };
          }
        });
      };

      // Update single_prediction
      const updatedSinglePrediction = data.single_prediction
        ? calculateSumForBlocks(data.single_prediction, true)
        : undefined;

      // Update single_predictions (percentiles)
      const updatedSinglePredictions = data.single_predictions
        ? Object.keys(data.single_predictions).reduce((acc, percentile) => {
          if (!data.single_predictions) {
            throw new Error("There is a currentPrediction without a single_predictions attribute")
          }
          acc[percentile] = calculateSumForBlocks(data.single_predictions[percentile]);
          return acc;
        }, {} as PercentilePredictions)
        : undefined;

      return {
        ...data,
        single_prediction: updatedSinglePrediction,
        single_predictions: updatedSinglePredictions,
      };
    };


    const handleChosenTimesInitialization = () => {

      if (props.chosenMemory.some((obj) => obj.clusterId === getClusterId())) {
        console.log("The memory loaded")
        const memoryEntry = props.chosenMemory.find(
          (obj) => obj.clusterId === getClusterId()
        );
        if (!memoryEntry?.chosenTimesSum) {
          throw new Error("There is some corrupted data");
        }
        chosenTimesSum.value = memoryEntry.chosenTimesSum;
        if (currentPrediction.value?.single_prediction) {
          // Initialize chosenTimesBlock with the predicted values
          chosenTimesBlock.value = currentPrediction.value.single_prediction.map(prediction => ({
            blockId: prediction.id,
            prediction: prediction.time,
            input: prediction.time,
            active: prediction.active,
          }));
        }
        translateSumToBlock();
        // Initialize chosenTimesSum with the predicted values
        if (!currentPrediction.value?.single_prediction) {
          throw new Error("no prediction available")
        }
      } else {
        console.log("The memory was empty, inited")
        if (currentPrediction.value?.single_prediction) {
          // Initialize chosenTimesBlock with the predicted values
          chosenTimesBlock.value = currentPrediction.value.single_prediction.map(prediction => ({
            blockId: prediction.id,
            prediction: prediction.time,
            input: prediction.time,
            active: prediction.active,
          }));
          if (!props.linePlanningSettings.stop_combinations) {
            throw new Error("Missing stop combinations")
          }
          const activeStops = getActiveStops(props.linePlanningSettings.stop_combinations);
          const chosenTimesActive = determineActiveStopsChosen(sortSum(props.linePlanningSettings.stop_combinations, chosenTimesBlock.value), activeStops);
          chosenTimesSum.value = initChosenSum(chosenTimesActive);
        }

      }
      updateAggregatedPrediction();
      calculateTotalChosen();
    };

    const initChosenSum = (chosenBlock: Chosen[]): Chosen[] => {
      let sum = 0;
      let sumActive = 0;
      const chosenSum = chosenBlock.map((item) => {
        if (sum === 0) {
          sumActive = 0;
        }
        if (!item.blockId) {
          return {
            ...item,
            missing: false,
            input: 0,
            locked: true,
          }
        }
        if (!item.prediction) {
          return {
            ...item,
            input: sumActive,
          };
        }
        if (item.active) {
          sumActive += item.prediction
          sum = 0;
          return {
            ...item,
            input: sumActive,
          };
        } else {
          sum += item.prediction;
          sumActive = sum;
          return {
            ...item,
            input: sum,
          };
        }
      });

      return chosenSum;
    }

    const distributeInputProportionally = (
      sumList: Chosen[],
      detailedList: Chosen[]
    ): Chosen[] => {
      const sumListMap = new Map(sumList.map((item) => [item.blockId, item]));
      let lastActiveIndex = -1;
      const ranges: [number, number][] = [];
      detailedList.forEach((item, index) => {
        if (item.active) {
          if (lastActiveIndex === -1) {
            ranges.push([0, index + 1]);
          } else if (lastActiveIndex !== -1) {
            ranges.push([lastActiveIndex + 1, index + 1]);
          }
          lastActiveIndex = index;
        }
      });
      if (lastActiveIndex !== detailedList.length - 1) {
        ranges.push([lastActiveIndex, detailedList.length]);
      }

      ranges.forEach((range) => {
        const rangeItems = detailedList.slice(range[0], range[1]);
        const sumItem = sumListMap.get(rangeItems[rangeItems.length - 1].blockId);
        if (sumItem && sumItem.input) {
          const result = rangeItems.reduce(
            (acc, dItem) => {
              if (dItem.missing) {
                acc.totalMissingInput += dItem.input ?? 0;
              } else {
                acc.totalDetailedInput += dItem.input ?? 0;
              }
              return acc;
            },
            { totalDetailedInput: 0, totalMissingInput: 0 }
          );
          var totalDetailedInput = result.totalDetailedInput;
          var totalMissingInput = result.totalMissingInput;
          const ratio = (sumItem.input - totalMissingInput) / totalDetailedInput;
          rangeItems.forEach((dItem) => {
            if (!dItem.input) {
              dItem.input = 0;
            } else if (!dItem.missing) {
              dItem.input *= ratio;
            }
          });
        }
      });
      return detailedList;
    };

    const translateSumToBlock = () => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("No settings")
      }
      const activeStops = getActiveStops(props.linePlanningSettings.stop_combinations);
      const chosenTimesBlockTemp = determineActiveStopsChosen(sortSum(props.linePlanningSettings.stop_combinations, chosenTimesBlock.value), activeStops);
      const chosenTimesSumTemp = determineActiveStopsChosen(sortSum(props.linePlanningSettings.stop_combinations, chosenTimesSum.value), activeStops);
      chosenTimesBlock.value = distributeInputProportionally(chosenTimesSumTemp, chosenTimesBlockTemp);
      updateAggregatedPrediction();
      calculatePercentagesForBlockTimes();

    };

    // Watchers for updating the UI on data changes
    watch(
      () => props.timeClusterTrigger,
      async () => {
        setTimeClusterInfo();
        updateTimeDayIdentifiers();
        await setCurrentPrediction();
        handleChosenTimesInitialization();
        updateAggregatedPrediction();
        handleSaveToMemory();
      }
    );

    const handleOnMounted = () => {
      setTimeClusterInfo();
      setDayClusterInfo();
      calculateAvailableTableHeigth();
      addEventListener('resize', calculateAvailableTableHeigth);
      updateTimeDayIdentifiers();

      setCurrentPrediction();

      handleChosenTimesInitialization();
      updateAggregatedPrediction();
    };

    onMounted(handleOnMounted)
    // Functions for setting the UI info and handling state
    const setTimeClusterInfo = () => {
      const timeArray = convertSettingsToTimeStrings(getActiveTimeClusters())[props.activeTimeCluster];
      if (timeArray.length === 1) {
        timeClusterInfo.value = timeArray[0]
        timeClusterInfoLength.value = 1;
      } else {
        timeClusterInfo.value = timeArray[0] + ' - ' + timeArray[timeArray.length - 1];
        timeClusterInfoLength.value = timeArray.length;
      }
    };

    const convertSettingsToDayStrings = (): string[] => {
      const dayStrings = props.linePlanningSettings.day_clusters
      let clusterLengths: number[] = [];
      const formattedDayStrings = dayStrings.map(cluster => {
        const daysMapped = cluster.map(day => dayMapper[day.day?.toLowerCase() || ''] || '');
        if (daysMapped.length === 1) {
          clusterLengths.push(1);
          return daysMapped[0]; // Return the single day
        } else {
          clusterLengths.push(daysMapped.length)
          return daysMapped[0] + ' - ' + daysMapped[daysMapped.length - 1]; // Return the first and last day
        }
      });
      dayClusterInfoLength.value = clusterLengths[props.activeDayCluster]
      return formattedDayStrings;

    };

    const setDayClusterInfo = () => {
      dayClusterInfo.value = convertSettingsToDayStrings()[props.activeDayCluster];
    };

    const toggleCollapseTable = () => {
      collapseTable.value = !collapseTable.value;
    };

    const handleSubmit = (event: Event) => {
      event.preventDefault();
    };

    const handleEnter = (event: KeyboardEvent) => {
      (event.target as HTMLInputElement).blur();
    };

    const getChosenTimeSum = (id: string): number => {
      if (chosenTimesSum.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimesSum.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.input === undefined) {
        return 0;
      }
      return Math.round(chosenTime.input);
    };

    const getChosenPercentageSum = (id: string): number => {
      if (chosenTimesSum.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimesSum.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.percentage === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentage);
    };

    const getChosenTimeBlock = (id: string): number => {
      if (chosenTimesBlock.value.length === 0) {
        return 0;
      }
      const chosenTime = chosenTimesBlock.value.find((item) => item.blockId === id);
      if (!chosenTime || chosenTime.input === undefined) {
        return 0;
      }
      return Math.round(chosenTime.input);
    };

    const getChosenPercentageBlock = (id: string): number => {
      if (chosenTimesBlock.value.length === 0) {
        return 0;
      }
      const chosenTime = chosenTimesBlock.value.find((item) => item.blockId === id);
      if (!chosenTime || chosenTime.percentage === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentage);
    };

    const updateChosenTimeSum = (id: string, value: string) => {
      const chosenTime = chosenTimesSum.value.find(item => {
        return item.blockId === id
      }

      );
      if (chosenTime) {
        chosenTime.input = mmssToSeconds(value);
      }
      translateSumToBlock();
      calculateTotalChosen();
      calculatePercentagesForAllChosenTimes();
      handleSaveToMemory();
    };

    const handleSaveToMemory = () => {
      emit("update-chosen-times", chosenTimesSum.value);
    };

    const computeRowClass = (route: Route, index: number): string => {
      if (collapseTable.value) {
        return "";
      }

      if (route.active) {
        return " font-bold";
      }

      if (route.type === 'missing') {
        return index % 2 !== 0 ? " !bg-red-200" : " !bg-red-100";
      }

      return "";
    };

    return {
      timeClusterInfo,
      timeClusterInfoLength,
      dayClusterInfo,
      dayClusterInfoLength,
      getActiveStops,
      aggregatedPrediction,
      activeStopCombinations,
      predictionLookup,
      collapseTable,
      toggleCollapseTable,
      displayedStopCombinations,
      handleEnter,
      handleSubmit,
      chosenTimesSum,
      getChosenTimeSum,
      updateChosenTimeSum,
      getChosenTimeBlock,
      chosenTimesBlock,
      computeRowClass,
      formatTimeInput,
      secondsToMMSS,
      startIncrementSum,
      stopIncrement,
      availableTableHeight,
      timeClusterInfoDiv,
      checkAvailableData,
      incrementPercentageSum,
      startIncrementPercentageSum,
      totalsum,
      totalsumchosen,
      lineNameLocal,
      journeyPatternCodeLocal,
      linePublicNumberLocal,
      formatPercentInput,
      determineButtonClassUp,
      determineButtonClassDown,
      beginAndEndStop,
      standardPercentage,
      getChosenPercentageSum,
      updateChosenPercentSum,
      stopIncrementPercentageSum,
      totalPercentageChosen,
      getChosenPercentageBlock,
      getSchedule,
      totalSchedule
    };
  },
});
</script>

<style scoped></style>
