import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e247444"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-container flex flex-row items-center justify-center my-1" }
const _hoisted_2 = { class: "flex-grow h-96 overflow-auto" }
const _hoisted_3 = { class: "block-route-cluster flex flex-col flex-grow overflow-hidden" }
const _hoisted_4 = { class: "relative flex flex-col items-start overflow-y-auto overflow-x-hidden whitespace-normal h-auto pl-2 pr-2 mb-0" }
const _hoisted_5 = { class: "relative flex flex-col items-start w-full" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { class: "text-sm" }
const _hoisted_9 = {
  key: 3,
  class: "text-sm ml-2 w-full text-red-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "w-16 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTypeAll()))
      }, " All "),
      _createElementVNode("button", {
        class: "w-16 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTypeNone()))
      }, " None "),
      _createElementVNode("button", {
        class: "w-16 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectTypeMeet()))
      }, " Meet "),
      _createElementVNode("button", {
        class: "w-20 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectTypeStartEnd()))
      }, " Start/eind ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeOrderRef, (block, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "block-container h-auto flex items-center mb-2"
              }, [
                (block.type !== 'rijtijd')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      ((block.type !== 'rijtijd' && block.type !== 'missing'))
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            "onUpdate:modelValue": ($event: any) => ((block.active) = $event),
                            onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.emitRouteOrderRef && _ctx.emitRouteOrderRef(...args))),
                            type: "checkbox",
                            class: "mr-2"
                          }, null, 40, _hoisted_7)), [
                            [_vModelCheckbox, block.active]
                          ])
                        : _createCommentVNode("", true),
                      (block.type !== 'missing')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass([_ctx.blockClasses(block), "flex items-center px-2 relative z-11"])
                          }, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getShortHandBlock(block.type as string)), 1)
                          ], 2))
                        : _createCommentVNode("", true),
                      (block.id_userstopname_combination && block.type !== 'missing')
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 2,
                            class: _normalizeClass([_ctx.lineClasses(block), "text-sm ml-2 w-full"])
                          }, _toDisplayString(block.id_userstopname_combination), 3))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(block.id_userstopname_combination), 1))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 64))
}