<template>
    <div v-if="daysDataAvailable" class="w-full">
        <div class="container mx-auto h-64 flex flex-col">
            <!--padding row-->
            <div class="h-4 flex">
                <div class="w-16 ml-3 h-4"></div>
                <div class="w-full border-b-2 h-4"></div>
                <div class="w-4  h-4"></div>
            </div>
            <!-- Row 1 -->
            <div class="flex flex-grow">
                <div class="w-4"></div>
                <!-- Div 1 -->
                <div class="flex-none w-12 h-full border-r-2 flex flex-col-reverse items-center justify-between">
                    <div v-for="(value, index) in yAxisValues" :key="index" class="flex items-center relative">
                        <span class="text-xs">{{ value }}</span>
                        <span class="absolute left-8 bg-gray-200"
                            :style="{ width: gridWidth + 'px', height: '1px' }"></span>
                    </div>
                </div>
                <!-- Div 2 -->
                <div ref="div2" class="div-2 w-full relative">
                    <div class="absolute top-0 left-0 flex z-40">
                        <button
                            class="w-7 h-7 rounded-sm p-0.5 mr-1 bg-slate-800 text-white flex items-center justify-center"
                            @click="addDraggableDiv">
                            <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${addBarSvg})` }"></div>
                        </button>
                        <button
                            class="w-7 h-7 rounded-sm p-0.5 bg-slate-800 text-white flex items-center justify-center"
                            @click="removeDraggableDiv">
                            <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${removeBarSvg})` }">
                            </div>
                        </button>
                    </div>
                    <div class="absolute top-1 right-1 flex z-40 bg-white border-gray-200 border-2 rounded-md">
                        <div class="legend flex items-center justify-center p-1">
                            <span
                                class="legend-indicator h-2 w-6 rounded-full bg-blue-200 mr-2 border-2 border-slate-800"></span>
                            <span class="legend-text text-xs mr-4">Rijtijd voorspeld</span>
                        </div>
                    </div>
                    <div class="flex-grow h-full relative">
                        <div v-for="(day, index) in daysBarData" :key="index"
                            class="absolute bottom-0 bar-div bg-blue-200 border-l-2 border-r-2 border-t-2 border-slate-800"
                            :style="{ height: day.height + '%', left: `calc(${day.xAxisPosition}% - ${barWidth / 2}px)`, width: '10px' }">
                        </div>
                        <div v-for="(draggable, index) in draggableDivs" :key="index"
                            class="hover-container absolute bottom-0"
                            :style="{ left: `calc(${draggable.position}% - ${10}px)` }"
                            @mousedown="startDrag($event, index)">
                            <div class="draggable-div bg-gray-600" :style="{ height: '75%', width: '4px' }">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-4 border-l-2"></div>
            </div>
            <!-- Row 2 -->
            <div class="flex h-12">
                <div class="w-4"></div>
                <!-- Div 3 -->
                <div class="flex-none w-12 h-ful flex items-center justify-center">

                </div>
                <!-- Div 4 -->
                <div class="flex-grow h-full border-t-2 flex flex-col items-center justify-center relative">
                    <div class="flex w-full justify-between">
                        <div v-for="(value, index) in xAxisValues" :key="index"
                            class="flex flex-col items-center label pr-2">
                            <span class="text-xs">{{ shorthandDays(value) }}</span>
                        </div>
                    </div>
                </div>
                <div class="w-4"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, watch, nextTick, ref, onMounted } from 'vue';
import { TimeClusterData, Day, DayData, DayClusterData } from '@/types'

export default defineComponent({
    name: 'BarChartDays',
    props: {
        daysData: {
            type: Array as PropType<Day[]>,
            required: true,
        },
        dayClusters: {
            type: Array as PropType<DayClusterData[][]>,
            required: true
        },
        barChartDayTrigger: {
            type: Number,
            required: true
        }
    },
    emits: ['route-single-bus', 'route-cluster-bus', 'update-day-cluster', 'barday-finished'],
    setup(props, { emit }) {
        // UI element references
        const barWidth = 10;
        const div2 = ref<HTMLElement | null>(null);
        const gridWidth = ref(0);
        const gridHeight = ref(0);
        const draggableDivPosition = ref(0);
        const startDragX = ref(0);
        const containerWidth = ref<number>(0);
        const addBarSvg = require('@/assets/add_bar.svg');
        const removeBarSvg = require('@/assets/remove_bar.svg');

        // State management
        const isDragging = ref(false);

        // Responsive data variables
        const daysDataAvailable = computed(() => props.daysData && props.daysData.length > 0);
        const draggableDivs = ref<{ position: number }[]>([]);
        const draggingIndex = ref<number | null>(null);
        const dayOrder = ref<string[]>(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
        const dayClusters = ref<DayData[][]>([]);
        const minTotalTime = ref<number>(0);
        const maxTotalTime = ref<number>(0);
        const yAxisValues = ref<string[]>([])
        const shorthandDays = (day: string): string => {
            const dayMap: { [key: string]: string } = {
                monday: 'ma',
                tuesday: 'di',
                wednesday: 'wo',
                thursday: 'do',
                friday: 'vr',
                saturday: 'za',
                sunday: 'zo'
            };
            return dayMap[day.toLowerCase()] || day;
        }


        // Computation of the chart dimensions
        const computeYAxisValues = () => {
            if (!props.daysData || props.daysData.length === 0) return [];
            let numberOfTicks = 6;
            const totalTimes = props.daysData.map(trip => trip.average_time as number);
            minTotalTime.value = Math.min(...totalTimes) - 150 - (Math.min(...totalTimes) % 150);
            maxTotalTime.value = Math.max(...totalTimes) + 300 + (150 - (Math.max(...totalTimes) % 150)); const tickInterval = (maxTotalTime.value - minTotalTime.value) / numberOfTicks;
            const ticks = [];

            for (let i = 0; i <= numberOfTicks; i++) {
                const tickValue = minTotalTime.value + i * tickInterval;
                const minutes = Math.floor(tickValue / 60);
                const seconds = Math.floor(tickValue % 60);
                ticks.push(`${minutes}:${seconds.toString().padStart(2, '0')}`);
            }
            yAxisValues.value = ticks;
        };

        const xAxisValues = computed(() => {
            const sortedDaysData = [...props.daysData].sort((a, b) => {
                return dayOrder.value.indexOf(a.day as string) - dayOrder.value.indexOf(b.day as string);
            });
            const uniqueDays = new Set<string>();
            sortedDaysData.forEach(day => {
                if (day.day) {
                    uniqueDays.add(day.day);
                }
            });
            return Array.from(uniqueDays);
        });

        const calculateGridDimensions = () => {
            if (div2.value) {
                containerWidth.value = div2.value.clientWidth;
                gridWidth.value = div2.value.clientWidth + 8;
                gridHeight.value = div2.value.clientHeight + 8;
            }

        }


        // Determining the data for the bars
        const daysBarData = computed<DayData[]>(() => {
            if (!daysDataAvailable.value) return [];

            const range = maxTotalTime.value - minTotalTime.value;
            const sortedDaysData = [...props.daysData].sort((a, b) => {
                return dayOrder.value.indexOf(a.day as string) - dayOrder.value.indexOf(b.day as string);
            });
            const days = sortedDaysData.map(day => day.day as string);
            const xAxisPositions = daysToPosition(days);

            return sortedDaysData.map((day, index) => {
                const normalizedHeight = ((day.average_time as number) - minTotalTime.value) / range * 100;
                return {
                    day: day.day,
                    height: normalizedHeight,
                    xAxisPosition: xAxisPositions[index],
                    seconds: day.average_time
                };
            });
        });


        // Position related functions
        const daysToPosition = (days: string[]): number[] => {
            const positions: number[] = [];
            const length = days.length;
            if (length === 1) {
                return [0];
            }
            const increment = 100 / (length - 1);
            for (let i = 0; i < length; i++) {
                positions.push(parseFloat((increment * i).toFixed(1)));
            }
            return positions;
        };


        // Functions to keep track of the different clusters formed by the draggable divs
        const updateClusters = () => {
            if (!daysBarData.value.length || !div2.value) return;

            const clusters: DayData[][] = [];
            const sortedBars = [...daysBarData.value]
                .filter(bar => bar.xAxisPosition !== undefined)
                .sort((a, b) => (a.xAxisPosition ?? 0) - (b.xAxisPosition ?? 0));
            const sortedDivs = draggableDivs.value.map(div => div.position).sort((a, b) => a - b);
            let currentCluster: DayData[] = [];

            sortedBars.forEach(bar => {
                if (bar.xAxisPosition === undefined) return;
                const barPosition = bar.xAxisPosition;
                if (sortedDivs.length === 0 || barPosition < sortedDivs[0]) {
                    currentCluster.push(bar);
                } else {
                    while (sortedDivs.length && barPosition >= sortedDivs[0]) {
                        if (currentCluster.length) {
                            clusters.push(currentCluster);
                        }
                        currentCluster = [];
                        sortedDivs.shift();
                    }
                    currentCluster.push(bar);
                }
            });

            if (currentCluster.length) {
                clusters.push(currentCluster);
            }
            dayClusters.value = clusters;
            handleUpdateSettings();
        };

        const handleUpdateSettings = () => {
            emit('update-day-cluster', dayClusters.value)
        }

        watch(daysDataAvailable, async (newVal) => {
            if (newVal) {
                await nextTick();
                computeYAxisValues();
                calculateGridDimensions();
                initializeDraggableDivs();
            }
        });

        watch(() => props.barChartDayTrigger, async (newVal) => {
            if (newVal) {
                computeYAxisValues();
                resizeHandler();
                calculateGridDimensions();
                initializeDraggableDivs();
                nextTick(() => {
                    emit('barday-finished');
                })
            }
        });

        const resizeHandler = () => {
            window.addEventListener("resize", calculateGridDimensions);
        };

        const handleOnMounted = () => {
            computeYAxisValues();
            resizeHandler();
            calculateGridDimensions();
            initializeDraggableDivs();
            nextTick(() => {
                emit('barday-finished');
            })
        }

        onMounted(handleOnMounted);


        // Code for initializing and animating the splitter divs
        const initializeDraggableDivs = () => {
            if (props.dayClusters.flat().length == 0) {
                draggableDivs.value = [{ position: 75 }]; // 75 starts it between the weekend
            } else {
                const spacing = 100 / (props.dayClusters.flat().length - 1);
                let offset: number = 0;
                const splitters: { position: number }[] = [];
                props.dayClusters.forEach((subArray, index) => {
                    if (index > 0) {
                        const position = offset + spacing / 2;
                        splitters.push({ position });
                        offset += subArray.length * spacing;
                    } else {
                        offset += (subArray.length - 1) * spacing;
                    }

                })
                draggableDivs.value = splitters;
            }
        }

        const startDrag = (event: MouseEvent, index: number) => {
            isDragging.value = true;
            startDragX.value = event.clientX;
            draggingIndex.value = index;
            document.addEventListener('mousemove', onDrag);
            document.addEventListener('mouseup', stopDrag);
        };

        const onDrag = (event: MouseEvent) => {
            if (isDragging.value && draggableDivs.value && div2.value && draggingIndex.value !== null) {
                const dx = event.clientX - startDragX.value;
                let paddingCompensation = 32; // Calibration for the padding
                const dxPercent = (dx / (containerWidth.value - paddingCompensation)) * 100;
                let newPosition = draggableDivs.value[draggingIndex.value].position + dxPercent;

                if (newPosition < 0) {
                    newPosition = 0;
                } else if (newPosition > 100) {
                    newPosition = 100;
                }

                draggableDivs.value[draggingIndex.value].position = newPosition;
                startDragX.value = event.clientX;
            }
        };

        const stopDrag = () => {
            isDragging.value = false;
            document.removeEventListener('mousemove', onDrag);
            document.removeEventListener('mouseup', stopDrag);
            if (draggingIndex.value !== null) {
                checkForOverlap(draggingIndex.value);
            }
            draggingIndex.value = null;
            updateClusters();

        };

        const checkForOverlap = (index: number) => {
            if (!div2.value) return;

            const draggableDiv = div2.value.querySelectorAll('.draggable-div')[index];
            if (!draggableDiv) return;

            const draggableWidth = draggableDiv.clientWidth;
            const bars = Array.from(div2.value.querySelectorAll('.bar-div'));

            bars.forEach(bar => {
                let draggableRect = draggableDiv.getBoundingClientRect();
                const barRect = bar.getBoundingClientRect();
                if (
                    draggableRect.left < barRect.right &&
                    draggableRect.right > barRect.left
                ) {
                    const overlapLeft = draggableRect.left - barRect.left;
                    const overlapRight = barRect.right - draggableRect.right;
                    if (Math.abs(overlapLeft) < Math.abs(overlapRight)) {
                        draggableDivs.value[index].position -= (draggableWidth + overlapLeft + 2) / containerWidth.value * 100;
                    } else {
                        draggableDivs.value[index].position += (draggableWidth + overlapRight + 2) / containerWidth.value * 100;
                    }
                    draggableRect = draggableDiv.getBoundingClientRect();
                }
            });
        };

        const addDraggableDiv = () => {
            let position = 50
            let threshold = 4
            const isTooClose = (newPosition: number) => {
                return draggableDivs.value.some(div => Math.abs(div.position - newPosition) < threshold);
            };
            while (isTooClose(position)) {
                position += threshold;
            }
            draggableDivs.value.push({ position: position });
            updateClusters();
        }

        const removeDraggableDiv = () => {
            draggableDivs.value.pop()
            updateClusters();
        }

        const getClusterWidth = (barCluster: TimeClusterData[]): string => {
            if (!barCluster.length) {
                return '0%';
            }
            const positions = barCluster.map(bar => bar.xAxisPosition).filter((pos): pos is number => pos !== undefined);

            if (positions.length === 0) {
                return '0%';
            }
            const minPosition = Math.min(...positions);
            const maxPosition = Math.max(...positions);
            if (minPosition === maxPosition) {
                return `5%`;
            } else {
                return `${maxPosition - minPosition}%`;
            }
        };

        return {
            yAxisValues,
            xAxisValues,
            daysDataAvailable,
            barWidth,
            gridWidth,
            gridHeight,
            div2,
            draggableDivs,
            draggableDivPosition,
            startDrag,
            getClusterWidth,
            daysBarData,
            shorthandDays,
            addDraggableDiv,
            removeDraggableDiv,
            addBarSvg,
            removeBarSvg

        };
    },
});
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.div-2 {
    padding-left: 16px;
    padding-right: 16px;
}

/* Container for hover effect */
.hover-container {
    @apply absolute bottom-0 flex justify-center;
    height: 100%;
    width: 20px;
    /* Expanded width for hover effect */
    cursor: ew-resize;
}

/* Skinny div */
.draggable-div {
    @apply bg-gray-600 absolute bottom-0;
    width: 4px;
    height: 75%;
    transition: all 0.3s ease;
    /* Smooth transition */
    z-index: 10;
}

/* Hover effect */
.hover-container::before {
    @apply absolute w-full h-full bg-black bg-opacity-0 transition-opacity duration-300 ease-in-out;
    content: '';
}

.hover-container:hover::before {
    @apply bg-opacity-10;
}
</style>