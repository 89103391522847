<template>
  <div class="select-container flex flex-row items-center justify-center my-1 ">
    <button
      class="w-16 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center"
      @click="selectTypeAll()">
      All
    </button>
    <button
      class="w-16 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center"
      @click="selectTypeNone()">
      None
    </button>
    <button
      class="w-16 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center"
      @click="selectTypeMeet()">
      Meet
    </button>
    <button
      class="w-20 bg-gray-200 hover:bg-gray-300 text-black mx-2 py-1 px-3 rounded flex items-center justify-center"
      @click="selectTypeStartEnd()">
      Start/eind
    </button>
  </div>
  <div class="flex-grow h-96 overflow-auto">
    <div class="block-route-cluster flex flex-col flex-grow overflow-hidden">
      <div
        class="relative flex flex-col items-start overflow-y-auto overflow-x-hidden whitespace-normal h-auto pl-2 pr-2 mb-0">
        <div class="relative flex flex-col items-start w-full">
          <div v-for="(block, index) in routeOrderRef" :key="index"
            class="block-container h-auto flex items-center mb-2">
            <div v-if="block.type !== 'rijtijd'" class="flex items-center">
              <input v-if="(block.type !== 'rijtijd' && block.type !== 'missing')" v-model="block.active" @change="emitRouteOrderRef" type="checkbox"
                class="mr-2">
              <div v-if="block.type !== 'missing'" :class="blockClasses(block)" class="flex items-center px-2 relative z-11">
                <span class="text-sm">
                  {{ getShortHandBlock(block.type as string) }}
                </span>
              </div>
              <span v-if="block.id_userstopname_combination && block.type !== 'missing'" :class="lineClasses(block)" class=" text-sm ml-2 w-full">
                {{ block.id_userstopname_combination }}
              </span>
              <span v-else class="text-sm ml-2 w-full text-red-400">
                {{ block.id_userstopname_combination }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, onMounted, watch } from 'vue';
import { Route, LinePlanningSettings } from '@/types';

export default defineComponent({
  name: 'LineClusteringOverview',
  emits: ['update-settings'],
  props: {
    routeOrder: {
      type: Array as PropType<Route[]>,
      required: true
    },
    settings: {
      type: Object as PropType<LinePlanningSettings>,
      required: true
    },
  },
  setup(props, { emit }) {
    // Reference to UI elements
    const blockRefs = ref<(HTMLElement | null)[]>([]);

    // Responsive data variables
    const routeOrderRef = ref<Route[]>([]);


    // Functions for determining block appearance
    const setBlockRefs = () => {
      blockRefs.value = blockRefs.value.slice(0, props.routeOrder.length);
    };

    const totalBlockWidth = computed(() => {
      return blockRefs.value.reduce((total, block) => {
        if (block) {
          return total + block.offsetWidth;
        }
        return total;
      }, 0);
    });

    const getShortHandBlock = (type: string): string => {
      if (type === 'meethalte') {
        return 'meet';
      }
      if (type === 'tijdhalte') {
        return 'tijd';
      }
      if (type === 'starthalte') {
        return 'start';
      }
      if (type === 'eindhalte') {
        return 'eind'
      }
      return type
    };

    const blockClasses = (block: Route) => {
      let baseClasses = 'm-0.5 p-0.5 rounded text-center text-sm items-center justify-center';

      let extraClasses = '';
      if (block.type === 'starthalte') {
        extraClasses = ' w-12 h-6 bg-green-400 text-black';
      } else if (block.type === 'meethalte') {
        extraClasses = ' w-12 h-6 bg-gray-500 text-black';
      } else if (block.type === 'eindhalte') {
        extraClasses = ' w-12 h-6 bg-red-400 text-black';
      } else if (block.type === 'rijtijd') {
        extraClasses = ' w-12 absolute h-1 bg-gray-400 transform -translate-y-1/2 z-0';
      } else if (block.type === 'tijdhalte') {
        extraClasses = ' w-12 h-6 bg-gray-300 text-black';
      } else {
        console.error(`Unknown block type: ${block.type}`);
      }

      return `${baseClasses} ${extraClasses}`;
    };

    const lineClasses = (block: Route) => {
      let textAboveLineClasses = '';
      if (block.type === 'rijtijd') {
        textAboveLineClasses = ' bottom-full mb-1';
      }
      return textAboveLineClasses;
    };


    // Functions for managing the initial stops based on start, meet and eind haltes
    const setInitialStops = (settings: LinePlanningSettings) => {
      if (settings && settings.stop_combinations && settings.stop_combinations.length > 0) {
        console.log("The setInitialStops function ran!", settings.stop_combinations)
        settings.stop_combinations.forEach((stop, index) => {
          if (!settings.stop_combinations) {
            throw new Error("No valid stop combinations");
          }
          if (index === 0) {
            stop.type = 'starthalte';
          } else if (index === settings.stop_combinations.length - 1) {
            stop.type = 'eindhalte';
          } else if (stop.type === 'missing') {
            stop.type = 'missing';
          } else if (stop.id_userstopcode_combination?.split('_')[0] !== stop.id_userstopcode_combination?.split('_')[1]) {
            stop.type = 'rijtijd';
          } else {
            stop.type = stop.istimingstop ? 'meethalte' : 'tijdhalte';
          }
        });

        routeOrderRef.value = settings.stop_combinations;
      } else {
        routeOrderRef.value.forEach(route => {
          if (['starthalte', 'eindhalte', 'meethalte'].includes(route.type as string)) {
            route.active = true;
          } else {
            route.active = false;
          }
        });
      }

      emit('update-settings', routeOrderRef.value);
    }


    // Handle mounting and watch for changes in relevant variables and properties
    watch(
      () => props.settings,
      (newSettings) => {
        if (newSettings) {
          setInitialStops(newSettings);
        }
      },
      { immediate: true, deep: true }
    );

    onMounted(() => {
      setBlockRefs();
      setInitialStops(props.settings);
    });


    // Functions for handling interactions with the UI
    const selectTypeAll = () => {
      routeOrderRef.value.forEach(route => {
        if (route.type === 'missing' || route.type === 'rijtijd') {
          route.active = false;
        } else {
          route.active = true
        }
      });
      emit('update-settings', routeOrderRef.value);
    };

    const selectTypeMeet = () => {
      routeOrderRef.value.forEach(route => {
        if (route.type === 'meethalte') {
          route.active = true;
        }
      });
      emit('update-settings', routeOrderRef.value);
    };

    const selectTypeNone = () => {
      routeOrderRef.value.forEach(route => {
        route.active = false;
      });
      emit('update-settings', routeOrderRef.value);
    };

    const selectTypeStartEnd = () => {
      routeOrderRef.value.forEach(route => {
        if (['starthalte', 'eindhalte'].includes(route.type as string)) {
          route.active = true;
        }
      });
      emit('update-settings', routeOrderRef.value);
    };

    const emitRouteOrderRef = () => {
      emit('update-settings', routeOrderRef.value);
      console.log("routeOrderRef", routeOrderRef.value)
    };


    // Watch for changes in routeOrderRef and emit update-settings event
    watch(routeOrderRef, (newVal) => {
      emit('update-settings', newVal);
    }, { deep: true });

    return {
      blockRefs,
      totalBlockWidth,
      getShortHandBlock,
      selectTypeAll,
      selectTypeNone,
      selectTypeMeet,
      routeOrderRef,
      selectTypeStartEnd,
      emitRouteOrderRef,
      blockClasses,
      lineClasses
    };
  },
});
</script>

<style scoped>
.block-container {
  transition: 0.3s;
}

.block-container:hover {
  background-color: #B7E2FD
}

.block-container.active {
  background-color: #93C5FD;
}
</style>