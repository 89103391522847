<template>
    <div class="user-selector">
      <h2 class="text-xl font-bold mb-4">Select or Create User</h2>
      <div v-if="isLoading">
        <LoadingAnimationComp/>
      </div>
      <div v-else>
        <div class="mb-4">
          <label for="existing-user" class="block text-sm font-medium text-white">Existing Users</label>
          <select id="existing-user" v-model="selectedUser" class=" bg-slate-700 mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-sm">
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </select>
          <button @click="selectUser" class="mt-2 px-4 py-2 bg-slate-300 text-black rounded-sm">Select User</button>
        </div>
        <div>
          <label for="new-user-name" class="block text-sm font-medium text-white">New User Name</label>
          <input id="new-user-name" v-model="newUserName" type="text" class="bg-slate-700 mt-1 block w-full pl-3 pr-3 py-2 border rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <label for="new-user-email" class="block text-sm font-medium text-white mt-2">New User Email</label>
          <input id="new-user-email" v-model="newUserEmail" type="email" class=" bg-slate-700 mt-1 block w-full pl-3 pr-3 py-2 border rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <button @click="createUser" class="mt-2 px-4 py-2 bg-slate-300 text-black rounded-sm">Create User</button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import axios from 'axios';
  import LoadingAnimationComp from '@/components/LoadingAnimationComp.vue';
  
  export default defineComponent({
    name: 'UserSelector',
    components: {
      LoadingAnimationComp
    },
    emits: ['user-selected'],
    setup(_, { emit }) {
      // Responsive data variables
      const users = ref<{ id: string, name: string }[]>([]);
      const selectedUser = ref<string | null>(null);
      const newUserName = ref<string>('');
      const newUserEmail = ref<string>('');

      // State management
      const isLoading = ref<boolean>(true);
  

      // Data collection from the backend
      const fetchUsers = async () => {
        try {
          const response = await axios.get<{ users: { id: string, name: string }[] }>(`${process.env.VUE_APP_BACKEND_URL}/users`);
          users.value = response.data.users;
          isLoading.value = false;
        } catch (error) {
          console.error('Failed to fetch users:', error);
        }
      };
  
      // Saving created users to the server
      const createUser = async () => {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users`, {
            name: newUserName.value,
            email: newUserEmail.value
          });
          users.value.push(response.data);
          console.log("this is from teh users ", users.value, response.data)
          selectedUser.value = response.data.user_id;
          localStorage.setItem('userId', response.data.user_id);
          localStorage.setItem('userName', response.data.username);
          emit('user-selected', {id: response.data.user_id, name: response.data.username});
          newUserName.value = '';
          newUserEmail.value = '';
        } catch (error) {
          console.error('Failed to create user:', error);
          alert("The username or email is already taken!")
        }
      };



      // Functions for handling local data
      const selectUser = () => {
        const user = users.value.find(u => u.id === selectedUser.value);
        if (user) {
          localStorage.setItem('userId', user.id);
          localStorage.setItem('userName', user.name);
          emit('user-selected', user);
        }
      };
  
      onMounted(fetchUsers);
  
      return {
        users,
        selectedUser,
        newUserName,
        newUserEmail,
        isLoading,
        selectUser,
        createUser
      };
    }
  });
  </script>
  
  <style scoped>
  .user-selector {
    max-width: 300px;
    margin: 0 auto;
  }
  </style>
  